import React, { useEffect, useState, useRef, useMemo } from "react";
import IMAGES from "../../Middleware/images";
import "../../Assets/Styles/organization-list.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import apiHelper from "../../Helper/api-helper";
import { Helmet } from "react-helmet";
import titles from "../../Helper/title";
import ERR_MESSAGE from "../../Helper/error-helper";
import DEVELOPMENT_CONFIG from "../../Helper/config";
import { useNavigate } from "react-router-dom";
import ReactLoader from "../../ControlComponents/react-loader";
import { ToastContainer, toast } from "react-toastify";
import TextField from "@mui/material/TextField";
import "react-toastify/dist/ReactToastify.css";
import ROUTES from "../../Middleware/routes";
import "../../Components/pagination";
import Pagination from "../../Components/pagination";
import ManageSubscription from "../../Components/manage-subscription";

let PageSize = 10;
let PageSize1 = 10;
const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 676,
  // bgcolor: "background.paper",
  // borderRadius: "20px",
  // boxShadow: 24,
  // border: "1px solid #6ED7EF",
  // p: 0,
  // outline: 0,
  // textAlign: "center",
};

const OrganizationList = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentPage1, setCurrentPage1] = useState(1);
  const [organizationList, setOrganizationList] = useState([]);
  const [filterOrganizationList, setFilterOrganizationList] = useState([]);
  const [isLoading, setLoading] = useState(false);

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [search, setSearch] = useState("");
  const [openDeactivate, setOpenDeactivate] = useState(false);
  const [openActivate, setOpenActivate] = useState(false);
  const [subModal, setSubModal] = useState(false);
  const [openAction, setOpenAction] = useState(false);

  const handleOpen = () => {
    document.body.classList.add("modal-open");

    setOpen(true);
  }

  const handleClose = () => {
    document.body.classList.remove("modal-open");

    setErrors("")
    setOpen(false);
    setFields({
      ...fields,
      userName: "",
      email: "",
    });
  };
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectedRows1, setSelectedRows1] = useState([]);

  let message = localStorage.getItem("message");

  useEffect(() => {
    if (message) {
      organizationSuccess(message);
      localStorage.removeItem("message");
    }
    return () => {
      message = null;
    };
  }, []);

  const [status, setStatus] = useState("")
  const [orgId, setOrgId] = useState(null);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [totalEmployee, setTotalEmployee] = useState(null);
  const [price, setPrice] = useState(null);

  const [activeRow, setActiveRow] = useState(null);
  const tableRef = useRef(null);
  const handleRowClick = (rowId) => {
    setActiveRow(rowId);
  };
  const handleDocumentClick = (e) => {
    if (tableRef.current && !tableRef.current.contains(e.target)) {
      setActiveRow(null);
    }
  };
  useEffect(() => {
    document.addEventListener("click", handleDocumentClick);
    return () => {
      document.removeEventListener("click", handleDocumentClick);
    };
  }, []);

  const toggleRowSelection = (id, inviteStatus) => {
    if (inviteStatus === 0) {
      organizationSuccess("Select a active, deactive or subscribed member");
      return;
    }

    if (selectedRows.includes(id)) {
      setSelectedRows(selectedRows.filter(rowId => rowId !== id));
    } else {
      setSelectedRows([...selectedRows, id]);
    }
  };

  const handleDeleteOpen = (id, status) => {
    document.body.classList.add("modal-open");
    setStatus(status)
    setSelectedRows1(prevSelectedRows => [...prevSelectedRows, id]);
    setOpenDelete(true);
  };

  const handleDeleteClose = () => {
    document.body.classList.remove("modal-open");
    setOpenDelete(false);
  }

  const handleActionOpen = (status) => {
    document.body.classList.add("modal-open");
    setStatus(status)
    setOpenAction(true)
  };

  const handleActionClose = () => {
    document.body.classList.remove("modal-open");

    setOpenAction(false);
  }

  const handleDeactivateOpen = (id, status) => {
    document.body.classList.add("modal-open");

    setStatus(status)
    setSelectedRows1(prevSelectedRows => [...prevSelectedRows, id]);
    setOpenDeactivate(true);
  }

  const manageSubModal = (item) => {
    document.body.classList.add("modal-open");

    setOrgId(item?.id);
    setStartDate(() => { return item?.start_date })
    setEndDate(() => { return item?.end_date })
    setTotalEmployee(() => { return item?.total_user_allow })
    setPrice(() => { return item?.plan_price })
    setSubModal(() => { return true });
  }

  const handleDeactivateClose = () => {
    document.body.classList.remove("modal-open");

    setOpenDeactivate(false);
  }

  const handleActivateOpen = (id, status) => {
    document.body.classList.add("modal-open");
    setSelectedRows1(prevSelectedRows => [...prevSelectedRows, id]);
    setOpenActivate(true);
    setStatus(status)
  };

  const handleActivateClose = () => {
    document.body.classList.remove("modal-open");
    setOpenActivate(false);
  }
  const [errors, setErrors] = useState("");
  const [fields, setFields] = useState({
    userName: "",
    email: "",
  });

  const handleChange = (e) => {
    setErrors("");
    const name = e.target.name;
    const value = e.target.value;
    setFields({
      ...fields,
      [name]: value,
    });
  };
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const emojiRegex =
      /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
    const { email, userName } = fields;
    if (!userName || userName.trim().length === 0) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.EMPTY_ORGNAME;
    } else if (userName.length < 3) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.INVALID_ORGNAME_MIN;
    } else if (userName.length > 30) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.INVALID_ORGNAME_MAX;
    } else if (emojiRegex.test(userName)) {
      formIsValid = false;
      errors["userName"] = ERR_MESSAGE.EMOJI;
    }
    if (!email || email.trim().length === 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_EMPTY;
    } else if (reg.test(email.toLowerCase()) === false) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID;
    }
    setErrors(errors);
    return formIsValid;
  };
  const navigate = useNavigate();
  const handleMemberList = (id, isActive) => {
    navigate(ROUTES.ORGANIZATION_MEMBER, {
      state: {
        id: id,
        isActive: isActive,
      },
    });
  };
  const organizationSuccess = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };

  const organizationError = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };
  async function postAPI(e) {
    e.preventDefault();
    if (handleValidation()) {
      let data = JSON.stringify({
        name: fields.userName,
        email: fields.email,
      });
      setLoading(true);
      let result = await apiHelper.postRequest(
        "organization/create-organization",
        data
      );

      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false);
        organizationSuccess(result.message);
        setSearch("");
        getAPI();
        handleClose();
        setFields({
          ...fields,
          userName: "",
          email: "",
        });
      } else {
        organizationError(result?.message);
        setLoading(false);
      }
    }
  }

  async function getAPI() {
    setLoading(true);
    let result = await apiHelper.getRequest("organization/organization-list");
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setOrganizationList(result?.body);

      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  organizationList.sort((a, b) => a?.name?.localeCompare(b.name));

  useEffect(() => {
    getAPI();
  }, []);

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return organizationList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, organizationList]);

  const currentTableData1 = useMemo(() => {
    const firstPageIndex = (currentPage1 - 1) * PageSize1;
    const lastPageIndex = firstPageIndex + PageSize1;
    return filterOrganizationList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage1, filterOrganizationList]);

  const successDelete = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };
  const successDeactivate = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };


  async function getActionApi(e) {
    e.preventDefault();
    let data = JSON.stringify({
      "id": selectedRows,
      "status": status
    });
    setLoading(true);
    let result = await apiHelper.postRequest(
      "auth/change-account-status",
      data
    );
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      setSelectedRows([]);
      setStatus("");
      successDeactivate(result.message);
      refreshFilteredOrganizationList();
      getAPI();
      handleActionClose();
    } else {
      organizationError(result.message);
      setLoading(false);
    }
  }

  async function getActionApi1(e) {
    e.preventDefault();
    let data = JSON.stringify({
      "id": selectedRows1,
      "status": status
    });
    setLoading(true);
    let result = await apiHelper.postRequest(
      "auth/change-account-status",
      data
    );
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      setSelectedRows1([]);
      setStatus("");
      successDeactivate(result.message);
      refreshFilteredOrganizationList();
      getAPI();
      handleActivateClose()
      handleDeactivateClose()
      handleDeleteClose()
    } else {
      organizationError(result.message);
      setLoading(false);
    }
  }

  const handleSearch = (e) => {
    let text = e.target.value.toLowerCase();
    setSearch(text);
    const filteredProducts = organizationList?.filter((product) =>
      product?.name?.toLowerCase().includes(text) ||
      product?.email?.toLowerCase().includes(text)
    );
    setFilterOrganizationList(filteredProducts);
  };

  const handleClear = () => {
    setSearch("");
  };

  const refreshFilteredOrganizationList = () => {
    const searchText = search.toLowerCase();
    const filteredProducts = organizationList?.filter((product) =>
      product?.name?.toLowerCase().includes(searchText) || product?.email?.toLowerCase().includes(searchText)
    );
    setFilterOrganizationList(filteredProducts);
  };
  useEffect(() => {
    refreshFilteredOrganizationList();
  }, [organizationList, search]);

  function calculateDaysLeft(endDate) {
    const currentDate = new Date();
    const endDateObj = new Date(endDate);
    if (endDate == null) {
      return "-"
    }
    if (currentDate >= endDateObj) {
      return 'Plan ended';
    }
    const timeDifference = endDateObj - currentDate;
    const daysLeft = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
    return daysLeft;
  }

  function updateDaysLeft() {
    organizationList.forEach((plan, index) => {
      const daysLeft = calculateDaysLeft(plan.end_date);
      const daysLeftElement = document.getElementById(`daysLeft_${index}`);
      if (daysLeftElement) {
        daysLeftElement.textContent = `${daysLeft} days left`;
      }
    });
  }

  useEffect(() => {
    updateDaysLeft();
    const intervalId = setInterval(updateDaysLeft, 1000);
    return () => clearInterval(intervalId);
  }, []);

  const handleFilesChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const allowedExtensions = ["xlsx", "xls"];
    const fileExtension = file.name.split(".").pop().toLowerCase();
    if (!allowedExtensions.includes(fileExtension)) {
      return;
    }
    const formData = new FormData();
    formData.append('attachments', file);
    try {
      await handleBulkUpload(formData);
      event.target.value = '';
    } catch (error) {
    }
  }

  const handleBulkUpload = async (formData) => {
    setLoading(true);
    let result = await apiHelper.postRequestUpload("organization/bulk-upload", formData);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false)
      successDeactivate(result?.message)
      getAPI()
    }
    else {
      setLoading(false)
      organizationError(result?.message)
    }
  };


  return (
    <>
      <Helmet>
        <title>{titles.organization}</title>
      </Helmet>
      <div className="loader">{isLoading && <ReactLoader />}</div>
      <section className="pt-5">
        <div className="container">
          <div className="row align-items-center pb-4">
            <div className="col-12 col-lg-3">
              <div className="list-head mb-2">
                <h2>List of organizations</h2>
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <div className="category-head d-block d-sm-flex justify-content-start justify-content-lg-end align-items-center gap-2 gap-xl-3 flex-wrap">
                <div className="search-category upload-search position-relative mb-2 mb-sm-0">
                  <input
                    type="serach"
                    placeholder="Search organization..."
                    value={search}
                    onChange={(e) => handleSearch(e)}
                  />
                  <img
                    src={IMAGES.SEARCH_ICON}
                    className="search-icon"
                    alt=""
                  />

                  {search !== "" && (
                    <img
                      src={IMAGES.CROSS_1}
                      className="searchCross"
                      style={{ cursor: "pointer" }}
                      onClick={handleClear}
                    />
                  )}
                </div>
                <div className="add-category mb-2 mb-sm-0" style={{ cursor: "pointer" }}>
                  <button
                    type="button"
                    className="yellow-btn add-category-btn d-flex align-items-center"
                    onClick={handleOpen}
                  >
                    add new organization{" "}
                    <img
                      src={IMAGES.ARROW_GREY}
                      alt="arrow"
                      className="img-fluid ms-2"
                    />
                  </button>
                </div>
                <div className="dropdown mb-2 mb-sm-0">
                  <a
                    type="button"
                    id="dropdownMenuButton1"
                    aria-expanded="false"
                    className={selectedRows.length > 0 ? "yellow-btn action-details" : "action-details yellow-btn d-flex align-items-center manage-btn"}
                    {...(selectedRows.length > 0 ? { "data-bs-toggle": "dropdown" } : {})}
                  >
                    Action
                  </a>
                  <ul
                    className="dropdown-menu"
                    style={{ zIndex: "1000" }}
                    aria-labelledby="dropdownMenuButton1 "
                  >
                    <li style={{ cursor: "pointer" }}>
                      <a
                        className="dropdown-item "
                        onClick={() =>
                          handleActionOpen("1")
                        }
                      >
                        Activate Account
                      </a>
                    </li>
                    <li style={{ cursor: "pointer" }}>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleActionOpen("2")
                        }
                      >

                        Deactivate Account
                      </a>
                    </li>
                    <li style={{ cursor: "pointer" }}>
                      <a
                        className="dropdown-item"
                        onClick={() =>
                          handleActionOpen("3")
                        }
                      >
                        Delete Account
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="position-relative">
                  <label htmlFor="fileUpload">
                    <img className="upload-icon" src={IMAGES.UPLOAD} />
                    <input
                      type="file"
                      accept=".xlsx, .xls"
                      multiple
                      className="upload-input"
                      onChange={handleFilesChange}
                    />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            {search.length <= 0 ? (
              <>
                <div className="col-12">
                  <div className="table-responsive organization-table">
                    <table className="table mb-0" ref={tableRef}>
                      <thead>
                        <tr>
                          <th scope="col">
                            <div class="form-check">
                            </div>
                          </th>
                          <th scope="col">Organization Name</th>
                          <th scope="col">Total Members</th>
                          <th scope="col">Email</th>
                          <th scope="col">Locations</th>
                          <th scope="col">Subscription</th>
                          <th scope="col">Days Left</th>
                          <th scope="col">Invite Status</th>
                          <th scope="col">Manage Subscription </th>
                          <th scope="col"></th>
                        </tr>
                      </thead>
                      <tbody >
                        {currentTableData?.map((item) => {
                          return (
                            <tr
                              key={item.id}
                              className={selectedRows?.includes(item.id) ? "active" : ""}
                              onClick={() => handleRowClick(item.id)}
                            >
                              <td>

                                <div className="form-check">
                                  <input
                                    className="form-check-input table-check"
                                    type="checkbox"
                                    value=""
                                    checked={selectedRows?.includes(item.id)}
                                    onChange={() => toggleRowSelection(item.id, item.invite_status)}
                                  />
                                </div>

                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleMemberList(item.id, item.is_active)
                                }
                              >
                                {item?.name?.replace(/-/g, ' ')}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleMemberList(item.id, item.is_active)
                                }
                              >
                                {item.total_member}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleMemberList(item.id, item.is_active)
                                }
                              >
                                {item?.email?.toLowerCase()}

                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleMemberList(item.id, item.is_active)
                                }
                              >
                                <div className="table-content">
                                  {item.location ? item.location : "-"}

                                </div>
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleMemberList(item.id, item.is_active)
                                }
                              >
                                {item.plan_id === 1 ? "midsize" : item.plan_id === 2 ? "large" : item.plan_id === 3 ? "Enterprise" : "-"}
                              </td>
                              <td
                                style={{ cursor: "pointer" }}
                                onClick={() =>
                                  handleMemberList(item.id, item.is_active)
                                }
                              >
                                {
                                  item.plan_id === 3 ? <>
                                    {calculateDaysLeft(item?.end_date)}
                                  </> : "-"
                                }

                              </td>
                              <td>
                                {item.is_active == 2 ? (
                                  "deactivated"
                                ) : (
                                  <>
                                    {item.invite_status === 0
                                      ? "invited"
                                      : "active"}
                                  </>
                                )}
                                {/* <span className="red-due-msg">.DUE SOON</span> */}
                              </td>
                              <td style={{ cursor: "pointer" }}>
                                {
                                  item.invite_status === 0 ?
                                    <>

                                      <button
                                        className="yellow-btn d-flex align-items-center manage-sub-btn manage-btn"

                                      >
                                        Manage Subscription
                                      </button></> :
                                    <>
                                      <button
                                        style={{ cursor: "pointer" }}
                                        className="yellow-btn d-flex align-items-center manage-sub-btn"
                                        onClick={() => manageSubModal(item)}
                                      >
                                        Manage Subscription
                                      </button>
                                    </>
                                }

                              </td>
                              <td>
                                {item.is_active == 2 ? (
                                  <>
                                    <div className="dropdown">
                                      <a
                                        type="button"
                                        id="dropdownMenuButton1"
                                        data-bs-toggle="dropdown"
                                        aria-expanded="false"
                                      >
                                        <img
                                          src={IMAGES.THREE_DOTS_ICONS}
                                          alt="3dots"
                                        />
                                      </a>
                                      <ul
                                        className="dropdown-menu"
                                        style={{ zIndex: "1000" }}
                                        aria-labelledby="dropdownMenuButton1 "
                                      >
                                        <li style={{ cursor: "pointer" }}>
                                          <a
                                            className="dropdown-item "
                                            onClick={() =>
                                              handleActivateOpen(item.id, "1")
                                            }
                                          >
                                            Activate Account
                                          </a>
                                        </li>
                                        <li style={{ cursor: "pointer" }}>
                                          <a
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleDeleteOpen(item.id, "3")
                                            }
                                          >
                                            Delete Account
                                          </a>
                                        </li>
                                      </ul>
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    {item.invite_status === 1 ? (
                                      <div className="dropdown">
                                        <a
                                          type="button"
                                          id="dropdownMenuButton1"
                                          data-bs-toggle="dropdown"
                                          aria-expanded="false"
                                        >
                                          <img
                                            src={IMAGES.THREE_DOTS_ICONS}
                                            alt="3dots"
                                          />
                                        </a>
                                        <ul
                                          className="dropdown-menu"
                                          style={{ zIndex: "1000" }}
                                          aria-labelledby="dropdownMenuButton1 "
                                        >
                                          <li style={{ cursor: "pointer" }}>
                                            <a
                                              className="dropdown-item "
                                              onClick={() =>
                                                handleDeactivateOpen(item.id, "2")
                                              }
                                            >
                                              Deactivate Account
                                            </a>
                                          </li>
                                          <li style={{ cursor: "pointer" }}>
                                            <a
                                              className="dropdown-item"
                                              onClick={() =>
                                                handleDeleteOpen(item.id, "3")
                                              }
                                            >
                                              Delete Account
                                            </a>
                                          </li>
                                        </ul>
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </>
                                )}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                  <div className="row align-items-center mt-2">
                    <div className="col-6">
                      <div className="pagination-left">
                        <nav aria-label="...">
                          <ul className="custom-pagination">
                            <li
                              className="page-item active"
                              aria-current="page"
                            >
                              <span className="page-link  cus-page-item">
                                Page
                              </span>
                            </li>
                            <li className="page-item cus-page-item">
                              <a className="page-link"> {currentPage}</a>
                            </li>
                            <li className="page-item cus-page-item">
                              <a className="page-link">of</a>
                            </li>
                            <li className="page-item cus-page-item">
                              <a className="page-link">
                                {Math.ceil(organizationList?.length / PageSize)}
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="pagination-right">
                        {organizationList?.length > PageSize && (
                          <Pagination
                            className="pagination-bar"
                            currentPage={currentPage}
                            totalCount={organizationList?.length}
                            pageSize={PageSize}
                            onPageChange={(page) => setCurrentPage(page)}
                          />
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : (
              <>
                {filterOrganizationList.length <= 0 ? (
                  <p className="notOrganization">No organization found</p>
                ) : (
                  <>
                    <div className="col-12">
                      <div
                        className="table-responsive organization-table"
                        ref={tableRef}
                      >
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div class="form-check">
                                </div>
                              </th>
                              <th scope="col">Organization Name</th>
                              <th scope="col">Total Members</th>
                              <th scope="col"> Email</th>
                              <th scope="col">Locations</th>
                              <th scope="col">Subscription</th>
                              <th scope="col">Days Left</th>
                              <th scope="col"> Invite Status</th>
                              <th scope="col"> Manage Subscription</th>
                              <th scope="col"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentTableData1?.map((item) => {
                              return (
                                <tr
                                  key={item.id}
                                  className={selectedRows.includes(item.id) ? "active" : ""}
                                  onClick={() => handleRowClick(item.id)}
                                >
                                  <td>

                                    <div className="form-check">
                                      <input
                                        className="form-check-input table-check"
                                        type="checkbox"
                                        value=""
                                        checked={selectedRows.includes(item.id)}
                                        onChange={() => toggleRowSelection(item.id, item.invite_status)}
                                      />
                                    </div>

                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleMemberList(item.id, item.is_active)
                                    }
                                  >
                                    {item?.name?.replace(/-/g, ' ')}
                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleMemberList(item.id, item.is_active)
                                    }
                                  >
                                    {item.total_member}
                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleMemberList(item.id, item.is_active)
                                    }
                                  >
                                    {item.email.toLowerCase()}

                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleMemberList(item.id, item.is_active)
                                    }
                                  >
                                    <div className="table-content">
                                      {item.location ? item.location : "-"}

                                    </div>
                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleMemberList(item.id, item.is_active)
                                    }
                                  >
                                    {item.plan_id === 1 ? "midsize" : item.plan_id === 2 ? "large" : item.plan_id === 3 ? "Enterprise" : "-"}
                                  </td>
                                  <td
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>
                                      handleMemberList(item.id, item.is_active)
                                    }
                                  >
                                    {calculateDaysLeft(item?.end_date)}
                                  </td>
                                  <td>
                                    {" "}
                                    {item.is_active == 2 ? (
                                      "deactivated"
                                    ) : (
                                      <>
                                        {item.invite_status === 0
                                          ? "invited"
                                          : "active"}
                                      </>
                                    )}
                                  </td>
                                  <td style={{ cursor: "pointer" }}>
                                    {
                                      item.invite_status === 0 ?
                                        <>

                                          <button
                                            className="yellow-btn d-flex align-items-center manage-sub-btn manage-btn"

                                          >
                                            Manage Subscription
                                          </button></> :
                                        <>
                                          <button
                                            style={{ cursor: "pointer" }}
                                            className="yellow-btn d-flex align-items-center manage-sub-btn"
                                            onClick={() => manageSubModal(item)}
                                          >
                                            Manage Subscription
                                          </button>
                                        </>
                                    }

                                  </td>
                                  <td>
                                    {item.is_active == 2 ? (


                                      <>
                                        <div className="dropdown">
                                          <a
                                            type="button"
                                            id="dropdownMenuButton1"
                                            data-bs-toggle="dropdown"
                                            aria-expanded="false"
                                          >
                                            <img
                                              src={IMAGES.THREE_DOTS_ICONS}
                                              alt="3dots"
                                            />
                                          </a>
                                          <ul
                                            className="dropdown-menu"
                                            style={{ zIndex: "1000" }}
                                            aria-labelledby="dropdownMenuButton1 "
                                          >
                                            <li style={{ cursor: "pointer" }}>
                                              <a
                                                className="dropdown-item "
                                                onClick={() =>
                                                  handleActivateOpen(item.id, "1")
                                                }
                                              >
                                                Activate Account
                                              </a>
                                            </li>
                                            <li style={{ cursor: "pointer" }}>
                                              <a
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleDeleteOpen(item.id, "3")
                                                }
                                              >
                                                Delete Account
                                              </a>
                                            </li>
                                          </ul>
                                        </div>
                                      </>
                                    ) : (
                                      <>
                                        {item.invite_status === 1 ? (
                                          <div className="dropdown">
                                            <a
                                              type="button"
                                              id="dropdownMenuButton1"
                                              data-bs-toggle="dropdown"
                                              aria-expanded="false"
                                            >
                                              <img
                                                src={IMAGES.THREE_DOTS_ICONS}
                                                alt="3dots"
                                              />
                                            </a>
                                            <ul
                                              className="dropdown-menu"
                                              style={{ zIndex: "1000" }}
                                              aria-labelledby="dropdownMenuButton1 "
                                            >
                                              <li style={{ cursor: "pointer" }}>
                                                <a
                                                  className="dropdown-item "
                                                  onClick={() =>
                                                    handleDeactivateOpen(
                                                      item.id, "2"
                                                    )
                                                  }
                                                >
                                                  Deactivate Account
                                                </a>
                                              </li>
                                              <li style={{ cursor: "pointer" }}>
                                                <a
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    handleDeleteOpen(item.id,"3")
                                                  }
                                                >
                                                  Delete Account
                                                </a>
                                              </li>
                                            </ul>
                                          </div>
                                        ) : (
                                          ""
                                        )}
                                      </>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                      <div className="row align-items-center mt-2">
                        <div className="col-6">
                          <div className="pagination-left">
                            <nav aria-label="...">
                              <ul className="custom-pagination">
                                <li
                                  className="page-item active"
                                  aria-current="page"
                                >
                                  <span className="page-link  cus-page-item">
                                    Page
                                  </span>
                                </li>
                                <li className="page-item cus-page-item">
                                  <a className="page-link"> {currentPage1}</a>
                                </li>
                                <li className="page-item cus-page-item">
                                  <a className="page-link">of</a>
                                </li>
                                <li className="page-item cus-page-item">
                                  <a className="page-link">
                                    {Math.ceil(
                                      filterOrganizationList?.length / PageSize1
                                    )}
                                  </a>
                                </li>
                              </ul>
                            </nav>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="pagination-right">

                            {filterOrganizationList?.length > PageSize1 && (
                              <Pagination
                                className="pagination-bar"
                                currentPage={currentPage1}
                                totalCount={filterOrganizationList?.length}
                                pageSize={PageSize1}
                                onPageChange={(page) => setCurrentPage1(page)}
                              />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </section>
      <div className="loader">{isLoading && <ReactLoader />}</div>

      <Modal
        open={openAction}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal modal-custom ">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleActionClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                {
                  status == 1 ? <h2>activate account</h2> : status == 2 ? <h2>deactivate account</h2>
                    : <h2>delete account</h2>

                }
              </div>
              <div className="mb-4  position-relative px-3 text-center">
                {status == 1 ? <p>
                  Are you certain that you want to proceed with activating the account ?
                </p> : status == 2 ? <p>
                  Are you certain that you want to proceed with deleting the account from the platform?
                </p> : <p> Are you certain that you want to proceed with deleting the account
                  from the platform?</p>}

              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getActionApi}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  {status == 1 ? "Activate" : status == 2 ? "Deactivate" : "Delete"

                  }
                  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h1>add new organization</h1>
              </div>
              <div className="position-relative px-3">
                <p>
                  Please enter email address below to send invite link. User can
                  register using the same link to join the platform.
                </p>
              </div>
              <form onSubmit={postAPI}>
                <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                  <TextField
                    fullWidth
                    label="Enter Organization name"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": {
                        color: "#231f20;",
                      },
                    }}
                    name="userName"
                    onChange={handleChange}
                    value={fields.userName}
                  />

                  {errors.userName && (
                    <span className="err_msg">{errors.userName}</span>
                  )}
                </div>
                <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                  <TextField
                    fullWidth
                    label="Enter email address"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="email"
                    onChange={handleChange}
                    value={fields.email}
                  />
                  {errors.email && <span className="err_msg">{errors.email}</span>}
                </div>
                <div className="mb-5 text-center position-relative submit-head">
                  <button
                    type="submit"
                    className="yellow-btn d-flex align-items-center"
                  >
                    Send Invite{" "}
                    <img
                      src={IMAGES.ARROW_GREY}
                      alt=""
                      className="img-fluid ms-2"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDeactivate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleDeactivateClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>deactivate account</h2>
              </div>
              <div className="mb-4  position-relative text-center">
                <p>
                  Are you certain that you want to proceed with deleting the account
                  from the platform?
                </p>
              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getActionApi1}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Deactivate{" "}
                  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      <Modal
        open={openDelete}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleDeleteClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>delete account</h2>
              </div>
              <div className="mb-4  position-relative text-center">
                <p>
                  Are you certain that you want to proceed with deleting the account
                  from the platform?
                </p>
              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getActionApi1}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Delete{" "}
                  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>
      <Modal
        open={openActivate}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={handleActivateClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h2>Activate account</h2>
              </div>
              <div className="mb-4  position-relative text-center">
                <p>
                  Are you certain that you want to proceed with activating the
                  account ?
                </p>
              </div>
              <div
                className="mb-5 text-center position-relative submit-head"
                onClick={getActionApi1}
              >
                <button
                  type="submit"
                  className="yellow-btn d-flex align-items-center"
                >
                  Activate{" "}
                  <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                </button>
              </div>
            </div>
          </div>
        </Box>
      </Modal>

      {orgId &&
      <ManageSubscription
      subModal={subModal}
      setSubModal={setSubModal}
        orgId={orgId}
        price={price}
        totalEmployee={totalEmployee}
        start_Date={startDate}
        end_Date={endDate}
        getAPI={getAPI}
      />
}
      <ToastContainer rtl />
    </>
  );
};

export default OrganizationList;
