import React, { useEffect, useState, useMemo } from "react";
import IMAGES from "../../Middleware/images";
import { useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import titles from "../../Helper/title";
import "../../Assets/Styles/setting-billing.css";
import apiHelper from "../../Helper/api-helper";
import ReactLoader from "../../ControlComponents/react-loader";
import DEVELOPMENT_CONFIG from "../../Helper/config";
import Pagination from "../../Components/pagination";
import { TextField } from "@mui/material";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import ERR_MESSAGE from "../../Helper/error-helper";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
let PageSize = 10;
const style = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: 676,
  // bgcolor: 'background.paper',
  // borderRadius: "20px",
  // boxShadow: 24,
  // border: "1px solid #6ED7EF",
  // p: 0,
  // outline: 0,
  // textAlign: "center",
};
const SettingBilling = () => {
  const [billingList, setBilingList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pdfLink, setPdfLink] = useState('');
  const [packageList, setPackageList] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [errors, setErrors] = useState("");
  const [fields, setFields] = useState({
    name: "",
    email: "",
    mobile: "",
    message: ""
  });
  const [open, setOpen] = useState(false);
  const handleOpen = () => {
    document.body.classList.add("modal-open");
    setOpen(true);
  }
  const handleClose = () => {
    document.body.classList.remove("modal-open");
    setOpen(false)
    setFields({
      ...fields,
      name: "",
      email: "",
      mobile: "",
      message: ""
    })
    setErrors("")
  };
  const handleChange = (e) => {
    setErrors("")
    const name = e.target.name;
    const value = e.target.value;
    setFields({
      ...fields,
      [name]: value,
    });

  };
  const handleKeyPress = (e) => {
    if (e.key === 'e' || e.key === 'E') {
      e.preventDefault();
    }
  };

  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
    const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
    const { email, name, mobile, message } = fields;
    if (!name || name.trim().length === 0) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.EMPTY_FULLNAME;
    } else if (name.length < 3) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_FULLNAME;
    }
    else if (name.length > 30) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.INVALID_FULLNAME_MAX;
    }
    else if (emojiRegex.test(name)) {
      formIsValid = false;
      errors["name"] = ERR_MESSAGE.EMOJI;
    }
    if (!email || email.trim().length === 0) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
    }
    else if (reg.test(email.toLowerCase()) === false) {
      formIsValid = false;
      errors["email"] = ERR_MESSAGE.EMAIL_INVALID
    }
    if (!mobile || mobile.trim().length === 0) {
      formIsValid = false;
      errors["mobile"] = ERR_MESSAGE.MOBILE_EMPTY
    }
    else if (mobile.length < 9) {
      formIsValid = false;
      errors["mobile"] = ERR_MESSAGE.INVALID_MOBILE_MIN
    }
    if (!message || message.trim().length === 0) {
      formIsValid = false;
      errors["message"] = ERR_MESSAGE.MESSAGE_EMPTY
    }
    setErrors(errors);
    return formIsValid;
  };
  const organizationSuccess = (msg) => {
    toast.success(msg,
      {
        autoClose: 5000,
      });
  }
  const organizationError = (msg) => {
    toast.success(msg,
      {
        autoClose: 5000,
      });
  }
  async function postAPI(e) {
    e.preventDefault();
    let id = localStorage.getItem("id")

    if (handleValidation()) {
      let data = JSON.stringify({
        "user_id": id,
        "name": fields.name,
        "email": fields.email,
        "phone_number": fields.mobile,
        "requirement": fields.message
      });
      setLoading(true);
      let result = await apiHelper.postRequest("organization/create-Custom-request", data)

      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false)
        organizationSuccess(result?.message)
        getAPI()
        handleClose()
        setFields({
          ...fields,
          name: "",
          email: "",
          mobile: "",
          message: ""
        })

      }
      else {
        organizationError(result.message)
        setLoading(false)
      }
    }
  }

  const handleRowClick = (rowId) => {
    if (selectedRows.includes(rowId)) {
      setSelectedRows(selectedRows.filter((id) => id !== rowId));
    } else {
      setSelectedRows([...selectedRows, rowId]);
    }
  };

  async function getInvoiceAPI(invoiceId) {
    setLoading(true);
    let result = await apiHelper.getRequest(`auth/invoice-download?invoice_id=${invoiceId}`);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setPdfLink(result?.body?.pdf);

      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  async function getAPI() {
    setLoading(true);
    let result = await apiHelper.getRequest("organization/billing-list");
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setBilingList(result?.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }
  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return billingList?.slice(firstPageIndex, lastPageIndex);
  }, [currentPage, billingList])

  async function getAPIPackageList() {
    setLoading(true);
    let result = await apiHelper.getRequest(`auth/package-list`);
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setPackageList(result.body);
      setLoading(false);
    } else {
      setLoading(false);
    }
  }

  const handleLargeClick = () => {
    const stripeCheckoutURL = `https://buy.stripe.com/00gcQrbgK6ATdig148`;
    window.location.href = stripeCheckoutURL;
  };
  const handleMidsizeClick = () => {
    const stripeCheckoutURL = `https://buy.stripe.com/cN203F4Sm3oHa646or`;
    window.location.href = stripeCheckoutURL;
  };

  useEffect(() => {
    if (pdfLink) {
      const link = document.createElement('a');
      link.href = pdfLink;
      link.target = '_blank';
      link.download = 'document.pdf';
      link.click();
    }
  }, [pdfLink]);

  useEffect(() => {
    getAPIPackageList();
    getAPI();
  }, []);


  return (

    <div>
      <Helmet>
        <title>{titles.billing}</title>
      </Helmet>
      <div className="loader">{isLoading && <ReactLoader />}</div>
      <section class="serach-result-section">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-12">
              <div class="billing-head mt-5 mb-4">
                <h2>Subscription Plans</h2>
              </div>
            </div>
            {packageList.map((pkg) => {
              return (
                <div class="col-12 col-md-6 col-xl-4">

                  <div class={`position-relative paln-box-main mb-4 mb-xl-0 ${pkg.is_package !== 0 ? 'plan-subscribe' : ''}`}>
                    <div class="plan-box d-flex justify-content-between align-items-center d-none ">
                      <div class="plan-left-head d-flex">
                        <img
                          src="assets/images/tapped_logo.png"
                          alt=""
                          class="img-fluid me-2"
                        />
                        <p class="f14-size fw-900 mt-1">
                          {(pkg?.name !== "enterprise custom pricing" ? pkg?.name : "enterprise")}
                        </p>
                      </div>
                      <div class="plan-right-head">
                        {pkg?.is_package === 0 ? (
                          <p class="f11-size fw-500 plan-tag">POPULAR</p>
                        ) : (
                          <input
                            class="form-check-input plan-form-check-input ms-2 mt-1"
                            type="checkbox"
                            value=""
                            checked
                          />
                        )}
                      </div>
                    </div>
                    <div class="plan-box-head text-center my-4 px-2 ">
                      <h3 class="text-lowercase mb-0 midsize-black large-blue enterprise-text plan-price">{(pkg?.name !== "enterprise custom pricing" ? pkg?.name : "enterprise")}</h3>
                      <h3 class="mb-0 plan-price me-2">{pkg.price == 0 ? "custom pricing" : `$${pkg.price}`}</h3>
                      {
                        pkg?.price == 0 ? <p class="f14-size month-cus space_empty"></p>
                          : <p class="f14-size month-cus"><i>per month billed annually</i></p>
                      }
                    </div>
                    <div class="plan-box-content px-2 text-center">
                      <p class="plan-box-text">{pkg.short_description}</p>
                    </div>
                    <div class="plan-box-btn my-3 px-2">
                      {pkg?.is_package === 0 ?
                        <>
                          {
                            pkg?.price == 0 ?
                              (
                                <button
                                  style={{ cursor: "pointer" }}
                                  onClick={handleOpen}
                                  type="submit"
                                  class="plan-btn position-relative justify-content-center mx-auto d-flex align-items-center"
                                >Contact us
                                  <img
                                    src={IMAGES.ARROW_GREY}
                                    alt=""
                                    class="img-fluid ms-2"
                                  />
                                </button>
                              ) :
                              <>
                                {
                                  pkg?.name === "large" && <>
                                    <button
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleLargeClick()
                                      }
                                      type="submit"
                                      class="plan-btn position-relative justify-content-center mx-auto d-flex align-items-center"
                                    >Subscribe Now
                                      <img
                                        src={IMAGES.ARROW_GREY}
                                        alt=""
                                        class="img-fluid ms-2"
                                      />
                                    </button>
                                  </>
                                }
                                {
                                  pkg?.name === "midsize" && <>
                                    <button
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        handleMidsizeClick()
                                      }
                                      type="submit"
                                      class="plan-btn position-relative justify-content-center mx-auto d-flex align-items-center"
                                    >Subscribe Now
                                      <img
                                        src={IMAGES.ARROW_GREY}
                                        alt=""
                                        class="img-fluid ms-2"
                                      />
                                    </button>
                                  </>
                                }

                              </>

                          }

                        </>
                        : (
                          <button
                            type="submit"
                            class="plan-btn blue-plan-btn position-relative d-flex align-items-center  justify-content-center mx-auto "
                          >
                            Your Plan{" "}
                            <img
                              src={IMAGES.CHECK_BUTTON}
                              alt=""
                              class="img-fluid ms-2"
                            />
                          </button>
                        )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

        </div>
      </section>
      <section className="pt-5">
        <div className="container">
          <div className="col-12">
            <div className="row align-items-center justify-content-center">
              <div className="row align-items-center">
                <div className="row align-items-center pb-4">
                  <div className="col-12 col-lg-5">
                    <div className="list-head">
                      <h2>payment history</h2>
                    </div>
                  </div>
                  <div className="col-12 col-lg-7">
                    <div className="category-head d-flex justify-content-start justify-content-lg-end align-items-center gap-2 gap-xl-5">
                      <div className="add-category position-relative">

                      </div>
                    </div>
                  </div>
                </div>
                {billingList?.length > 0 ? (
                  <>
                    <div className="col-12">
                      <div className="table-responsive organization-table member-table ">
                        <table className="table mb-0">
                          <thead>
                            <tr>
                              <th scope="col">
                                <div class="form-check">

                                </div>
                              </th>
                              <th scope="col">Date of Payment</th>
                              <th scope="col">Subscription</th>
                              <th scope="col">Status</th>
                              <th scope="col">Invoice</th>
                            </tr>
                          </thead>
                          <tbody>
                            {currentTableData.map((org) => {
                              return (
                                <tr className={selectedRows.includes(org.id) ? 'active' : ''}>
                                  <td scope="col">
                                    <div class="form-check">
                                      <input
                                        className="form-check-input table-check"
                                        type="checkbox"
                                        value=""
                                        id={`checkbox-${org.id}`}
                                        checked={selectedRows.includes(org.id)}
                                        onChange={() => handleRowClick(org.id)}
                                      />
                                    </div>
                                  </td>
                                  <td>{org?.start_date}</td>
                                  <td>{org?.plan_data?.name}</td>
                                  <td>
                                    <div className="table-content">
                                      <p className="table-content-item item-paid">
                                        Paid
                                      </p>
                                    </div>
                                  </td>
                                  <td style={{ cursor: "pointer" }} onClick={() => getInvoiceAPI(org.invoice_id)}>
                                    <img src={IMAGES.PDF_ICON} alt="" />
                                    #{org?.start_date}
                                  </td>
                                </tr>
                              );
                            })}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-6 mt-2">
                      <div className="pagination-left">
                        <nav aria-label="...">
                          <ul className="custom-pagination">
                            <li
                              className="page-item active"
                              aria-current="page"
                            >
                              <span className="page-link  cus-page-item">
                                Page
                              </span>
                            </li>
                            <li className="page-item cus-page-item">
                              <a className="page-link">{currentPage}</a>
                            </li>
                            <li className="page-item cus-page-item">
                              <a className="page-link">of</a>
                            </li>
                            <li className="page-item cus-page-item">
                              <a className="page-link">
                                {Math.ceil(billingList?.length / PageSize)}
                              </a>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                    <div className="col-6 mt-2">
                      <div className="pagination-right">
                        <nav aria-label="Page navigation example">

                          {billingList?.length > PageSize && (
                            <Pagination
                              className="pagination-bar"
                              currentPage={currentPage}
                              totalCount={billingList?.length}
                              pageSize={PageSize}
                              onPageChange={(page) => setCurrentPage(page)}
                            />
                          )}
                        </nav>
                      </div>
                    </div>
                  </>
                ) : (
                  <p className="notMember">No payment history found</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <div class="modal-custom">
          <Box sx={style} className="modal modal-custom ">
            <div className="modal-dialog modal-dialog-centered modal-dialog-width">
              <div className="modal-content modal-content-custom">
                <div onClick={handleClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                  <img src={IMAGES.CROSS} />
                </div>
                <div className="signin-head text-center pb-3">
                  <h1>enterprise custom pricing</h1>
                </div>
                <div className="position-relative">
                </div>
                <form onSubmit={postAPI}>
                  <div className={errors?.name ? "my-3 my-lg-1 position-relative mx-1 mx-sm-5" : "my-3 my-lg-4 position-relative mx-1 mx-sm-5"}>
                    <TextField
                      fullWidth
                      label="Name"
                      variant="outlined"
                      sx={{
                        "& fieldset": {
                          border: "2px solid #231f20",
                          borderRadius: "30px",
                        },
                        "& .MuiInputLabel-shrink": {
                          color: "#231f20;",
                        },
                      }}
                      name="name"
                      onChange={handleChange}
                      value={fields.name}
                    />

                    {errors.name && <span className="err_msg">{errors.name}</span>}
                  </div>
                  <div className={errors?.email ? "my-3 my-lg-1 position-relative mx-1 mx-sm-5" : "my-3 my-lg-4 position-relative mx-1 mx-sm-5"}>
                    <TextField fullWidth label="Email" variant="outlined"
                      sx={{
                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                      }}
                      name="email"
                      onChange={handleChange}
                      value={fields.email} />
                    {errors.email && <span className="err_msg">{errors.email}</span>}
                  </div>
                  <div className={errors?.mobile ? "my-3 my-lg-1 position-relative mx-1 mx-sm-5" : "my-3 my-lg-4 position-relative mx-1 mx-sm-5"}>
                    <TextField fullWidth label="Mobile number" variant="outlined" type="number"
                      sx={{
                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                      }}
                      name="mobile" onChange={handleChange} onKeyDown={handleKeyPress}
                      value={fields.mobile.slice(0, 15)} />
                    {errors.mobile && <span className="err_msg">{errors.mobile}</span>}
                  </div>
                  <div className={errors?.message ? "my-3 my-lg-1 position-relative mx-1 mx-sm-5" : "my-3 my-lg-4 position-relative mx-1 mx-sm-5"}>
                    <TextField fullWidth label="Requirement" variant="outlined"
                      sx={{
                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                      }}
                      name="message" multiline
                      rows={6}
                      onChange={handleChange}
                      value={fields.message}
                    />
                    {errors.message && <span className="err_msg">{errors.message}</span>}
                  </div>

                  <div className="mb-3  text-center position-relative submit-head">
                    <button type="submit" className="yellow-btn d-flex align-items-center">Save<img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                  </div>
                </form>
              </div>
            </div>
          </Box>
        </div>
      </Modal>
      <ToastContainer rtl />
    </div>

  );

};

export default SettingBilling;
