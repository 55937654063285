import * as React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";

export default function DatePickerValue(props) {
  const handleChange = (newValue) => {
    console.log("newValue", newValue)
    props.setErrors("")
    const inputDate = new Date(newValue?.$d);
    const formattedDate = dayjs(inputDate)?.format("YYYY-MM-DD");
    props?.setFn1(newValue);

    if (formattedDate === "Invalid Date") {
      props?.setFn("");

    } else {
      props?.setFn(formattedDate);

    }
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>

      <DatePicker
        label={props?.label}
        value={dayjs(props?.value)}
        onChange={(newValue) => handleChange(newValue)}
      />
    </LocalizationProvider>
  );
}
