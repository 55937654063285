import DEVELOPMENT_CONFIG from "./config"
import axios from "axios"

export default {
  postRequest: function (url, data) {
    let token = localStorage.getItem("token")
    var config = {
      method: "post",
      url: DEVELOPMENT_CONFIG.base_url + url,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    const response = axios(config)
      .then(async (response) => {
        if (response.data.code === DEVELOPMENT_CONFIG.statusCode) {
          return response.data
        }
        else {
          return response.data
        }
      })
      .catch((error) => {
        return error.response.data
      });
    return response;
  },

  postRequestUpload: function (url, data) {
    let token = localStorage.getItem("token")
    var config = {
      method: "post",
      url: DEVELOPMENT_CONFIG.base_url + url,
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${token}`
      },
      data: data
    };
    const response = axios(config)
      .then(async (response) => {
        if (response.data.code === DEVELOPMENT_CONFIG.statusCode) {
          return response.data
        }
        else {
          return response.data
        }
      })
      .catch((error) => {
        return error.response.data
      });
    return response;
  },

  getRequest: function (url, data) {
    let token = localStorage.getItem("token")

    var config = {
      method: "get",
      url: DEVELOPMENT_CONFIG.base_url + url,
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`
      },
      data: data,
    };
    const response = axios(config)
      .then(async (response) => {
        if (response.data.code === DEVELOPMENT_CONFIG.statusCode) {
          return response.data
        }
      })
      .catch((error) => {
        return error.response.data
      });
    return response;
  },

}