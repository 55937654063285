import React, { useEffect } from 'react'
import "../Assets/Styles/error-page.css"
import IMAGES from '../Middleware/images'
import ROUTES from '../Middleware/routes'
import { useNavigate, useLocation } from 'react-router-dom'

function ErrorPage() {
    const navigate = useNavigate()
    const location = useLocation()
    const message = location?.state?.status

    return (
        <>
            <body>
                <section className="email-head">
                    <div className="container">
                        <div className="row justify-content-center align-items-center h-100">
                            <div className="col-12">
                                <div className="text-center">
                                    <img src={IMAGES.ERROR_IMG} alt="" className="img-fluid mb-2 mb-lg-4" />
                                    <p className="email-text">{message == "deactivated" || message == "deleted" ? " Account no longer exists." : "The account is already activated,"}
                                        {message == "activated" && <a href="" className="email-link" onClick={() => navigate(ROUTES.SIGN_IN)} style={{ textDecoration: "underline" }}>Please sign in.</a>}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </body>
        </>
    )
}

export default ErrorPage