import React, { useEffect, useState } from 'react'
import IMAGES from '../Middleware/images'
import '../Assets/Styles/steps.css'
const Steps = (props) => {
  const handleChange = (e) => {
    props?.setField(e.target.value)
  }
  useEffect(() => {
    const extractCatId = () => {
      const dataArray = props?.categoryList[props?.steps - 2]?.subcatdata || props?.categoryList[props?.steps - 2]?.osubcatdata;
      if (dataArray && dataArray.length > 0) {
        const catIdFromDataArray = dataArray[0]?.cat_id;
        props?.setCatId(catIdFromDataArray);
      }
    };
    extractCatId();
  }, [props?.categoryList, props?.steps])

  const handleKeydown = (e) => {
    if ((e.code === "Enter" || e.code === "NumpadEnter" || e.key === "," || e.key === "Enter") && e.target.value.trim().length !== 0) {
      const inputValue = e.target.value.trim()
      const matchingObject = getMatchingObject(inputValue?.toLowerCase());
      if (!matchingObject) {
        props.textApi(props.catId);
      } else {
        props.chipClick(matchingObject);
      }
      handleClear();
    }
  }

  const getMatchingObject = (fieldName) => {
    const dataArray = props?.categoryList[props?.steps - 2]?.subcatdata
      ? props?.categoryList[props?.steps - 2]?.subcatdata
      : props?.categoryList[props?.steps - 2]?.osubcatdata;
    if (!dataArray) return null;
    return dataArray.find(item => item.name.toLowerCase() === fieldName) || null;
  };

  const dataArray = props?.categoryList[props?.steps - 2]?.subcatdata ? props?.categoryList[props?.steps - 2]?.subcatdata : props?.categoryList[props?.steps - 2]?.osubcatdata

  const handleClear = () => {
    props?.setField("")
  }

  return (
    <>
      <section className="category-step-two">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12">
              <div className="category-step text-center">
                <p>haptapt works best when you add as many details (haptapt filter tags) as possible so you and your coworkers show up in the most relevant searches.</p>
                <p>Only add what you’re comfortable with coworkers knowing about you.</p>
                <div className="details-head mt-5 mb-4 text-center">
                  <a href="" className="step-btn ">Step {props?.steps}/{props?.categoryList.length + 1}</a>
                </div>
              </div>
              <div className="category-experience-step text-center">
                <h2 className="my-4">{props?.categoryList[props?.steps - 2]?.name}</h2>
                <div className="col-lg-6 mx-auto">
                  <div className="text-start position-relative">
                    <input
                      value={props?.field ? props?.field?.replace(/,/g, '') : ''}
                      onChange={handleChange}
                      onKeyDown={handleKeydown}
                      type="text" className="form-control comm-radius input-shadow comm-input" placeholder="Type here ..." />
                    {
                      props?.field == "," ? "" : props?.field ? <div className='icon-cross' onClick={handleClear}>
                        <img src={IMAGES.CROSS_1} />
                      </div> : ""
                    }
                  </div>
                  <div className="category-exp-filters my-4" >
                    {dataArray?.map((item, index) => (
                      <>
                        <p onClick={() => props?.chipClick(item)} key={item?.id} style={{ cursor: "pointer" }} className={props?.chipActiveArr[props?.steps - 2][item?.id] ? " category-filter-chips active" : " category-filter-chips"}><span >{item?.name}</span> <img src={IMAGES.CHIP_CROSS} className="chip-img" alt="img-fluid" /></p>
                      </>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Steps;