import React, { useRef, useState } from 'react';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Chip from '@mui/material/Chip';

const libraries = ['places'];

function LocationSearch(props) {
    const [inputValue, setInputValue] = useState('');
    const searchBoxRef = useRef(null);
    const handlePlaceSelect = (place) => {
        props?.setSelectedField("placesArr")
        props?.setErrorMessage('');
        props?.setErrorMessage1('');
        if (place && place.formatted_address) {
            props?.setFields({
                ...props?.fields,
                placesArr: [
                    ...props?.fields?.placesArr, place.formatted_address,
                ],
                latitude: `${place.geometry.location.lat()}`,
                longitude: `${place.geometry.location.lng()}`,
                place: ""
            })
            setInputValue('');
        }
    };

    const handleDelete = (location) => {
        if (props?.fields?.placesArr.length === 1) {
            props?.setSelectedField(null);
        }
        props?.setFields({
            ...props?.fields,
            placesArr: props?.fields?.placesArr.filter((loc) => loc !== location)
        })
    };

    return (
        <LoadScript googleMapsApiKey="AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg" libraries={libraries}>
            <StandaloneSearchBox
                ref={searchBoxRef}
                onLoad={(searchBox) => (searchBoxRef.current = searchBox)}
                onPlacesChanged={() => {
                    props?.setErrorMessage('');
                    const places = searchBoxRef?.current?.getPlaces();
                    if (places?.length > 0) {
                        handlePlaceSelect(places[0]);
                    }
                }}

            >
                <Autocomplete

                    multiple
                    value={props?.fields?.placesArr}
                    onChange={(_, newValue) => {
                        props?.setFields({
                            ...props?.fields,
                            placesArr: newValue,
                        })

                    }

                    }
                    options={[]}
                    inputValue={inputValue}
                    PopperComponent={(params) => (
                        <div {...params} style={{ display: "none" }} />
                    )}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            placeholder=''
                            variant="outlined"
                            onFocus={() => {
                                props?.setLabelObj({
                                    ...props?.labelObj,
                                    location: "Location"
                                })
                            }}
                            onKeyDownCapture={(e) => {
                                if (e.key === "Enter" && e.target.value.length > 0) {
                                    e.preventDefault()
                                }
                                if (e.key === "Backspace" || e.key === "Delete") {
                                    if (props?.fields?.placesArr.length === 1) {
                                        props?.setSelectedField(null);
                                    }
                                }
                            }}
                            onBlurCapture={(e) => {
                                if (e.target.value.length > 0 || props?.fields.placesArr.length > 0) {
                                    props?.setLabelObj({
                                        ...props?.labelObj,
                                        location: "Location"
                                    })
                                }
                                else {
                                    props?.setLabelObj({
                                        ...props?.labelObj,
                                        location: "Location: (i.e., New York)"
                                    })
                                }
                            }}
                            label={props?.labelObj?.location}
                            sx={{
                                "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                "& .MuiInputLabel-shrink": { color: "#231f20;" }
                            }}
                            // value={props?.fields?.place}
                            onChange={(e) => {
                                props?.setErrorMessage1('');
                                props?.setErrorMessage('');
                                const sanitizedInput = e.target.value.replace(/[^a-zA-Z0-9\s]+/g, '');
                                setInputValue(sanitizedInput);
                                props?.setFields({
                                    ...props?.fields,
                                    place: sanitizedInput,

                                })
                            }}
                            InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                    <>

                                    </>
                                ),
                            }}
                        />
                    )}
                    renderTags={(value, getTagProps) =>
                        value.map((option, index) => (
                            <Chip
                                key={index}
                                label={option}
                                sx={{
                                    backgroundColor: "#EBFBFF",
                                    color: "#231F20",
                                    "& .MuiChip-deleteIcon": {
                                        color: ""
                                    }
                                }}
                                {...getTagProps({ index })}
                                onDelete={() => handleDelete(option)}
                            />
                        ))
                    }

                />
            </StandaloneSearchBox>
        </LoadScript>
    );
}

export default LocationSearch;
