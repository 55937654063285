
import React, { useEffect, useState } from 'react';
import "../Assets/Styles/profile.css"
import apiHelper from '../Helper/api-helper';
import ReactLoader from '../ControlComponents/react-loader';
import DEVELOPMENT_CONFIG from "../Helper/config"
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from 'react-router-dom';
import ROUTES from '../Middleware/routes';

const ProfileComponent = () => {
    const location = useLocation()
    var str = window.location.href;
    str = str.split("/");
    const emailLink = str[3].includes("tapped")
    const extractedText = window.location.href.split("tapped=")[1];
    const userId = location.state?.id;
    const [isLoading, setLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [superAdminDetail, setSuperAdminDetail] = useState("")
    const navigate = useNavigate()
    const [message, setMessage] = useState()
    
    useEffect(() => {
        if (emailLink == false) {
            if (userId !== undefined) {
                async function getMyDetailAPI() {
                    let data = JSON.stringify({
                        "id": userId
                    });
                    setLoading(true);
                    try {
                        let result = await apiHelper.postRequest("auth/view-profile", data);
                        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                            setLoading(false)
                            setSuperAdminDetail(result.body.user_profile);
                            setCategoryList(result?.body.categories);
                            setMessage(result.body.message);
                        }
                    } catch (error) {
                        setLoading(false);
                    }
                }
                getMyDetailAPI();
            }
            else if (userId == undefined) {
                let role = localStorage.getItem("role")
                if (role == 3) {
                    navigate(ROUTES.ORGANIZATION_LIST)
                }
                else if (role == 2) {
                    navigate(ROUTES.SEARCH_EMPLOYEES)
                }
                else if (role == 1) {
                    navigate(ROUTES.SEARCH_DATABASE)
                }
            }
        }


    }, [userId, navigate]);


    useEffect(() => {
        let token = localStorage.getItem("token")
        if (emailLink) {
            if (userId == undefined && emailLink && token) {
                async function getMyDetailAPI() {
                    let data = JSON.stringify({
                        "id": extractedText
                    });
                    setLoading(true);
                    try {
                        let result = await apiHelper.postRequest("auth/view-profile", data);
                        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                            setSuperAdminDetail(result.body.user_profile);
                            setCategoryList(result?.body.categories);
                            setMessage(result.body.message);
                            setLoading(false)
                        }
                    } catch (error) {
                        setLoading(false);
                    }
                }
                getMyDetailAPI();
            }
            else if (!token) {
                navigate(ROUTES.SIGN_IN)
            }
        }

    }, [emailLink, extractedText, navigate]);


    return (
        <>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className='pt-5'>
                <div className='container'>
                <div className="row">
                    <div className="col-12 col-xl-3">
                        <div className="profile-heading">
                            <h2>Profile</h2>
                        </div>
                        <div className="company-info back-shadow mb-3">
                            <div className="p-4">
                                <div className="company-section-sub-head d-flex justify-content-between py-3 ps-xxl-4 pe-xxl-0 align-items-center">
                                    <h2 className="company-info-head mb-0">{superAdminDetail?.name?.replace(/-/g, ' ')}
                                    </h2>
                                </div>
                                <ul className="sub-company-list mb-2">
                                    <li className="sub-company-list-item">
                                        <label className="sq-radio">
                                            <input type="checkbox" checked="checked" name="radio" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <div className='label-profile'>
                                            <p className="pe-1 label-head">Email :</p>
                                            <p className="fw-900 one-line-text text-cus-align">{superAdminDetail?.email}</p>
                                        </div>
                                    </li>
                                    <li className="sub-company-list-item">
                                        <label className="sq-radio">
                                            <input type="checkbox" checked="checked" name="radio" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <div className='label-profile'>
                                            <p className="pe-1 label-head">Location :</p>
                                            <p className="fw-900 one-line-text text-cus-align">{superAdminDetail?.location}</p>
                                        </div>
                                    </li>
                                    <li className="sub-company-list-item">
                                        <label className="sq-radio">
                                            <input type="checkbox" checked="checked" name="radio" />
                                            <span className="checkmark"></span>
                                        </label>
                                        <div className='label-profile'>
                                            <p className="pe-1 label-head">Role :</p>
                                            <p className="fw-900 one-line-text text-cus-align">{superAdminDetail?.designation}</p>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-9 pt-4 pt-xl-0">
                        <div className="row">
                            {
                                message && <div className="col-12">
                                    <div className="category-list">
                                        <ul>

                                            <li  >
                                                <div className="list-category-main d-flex justify-content-between align-items-center">
                                                    <div className="list-category-left">
                                                        <div className="list-category-head ">
                                                            <p>Message from {superAdminDetail?.name?.replace(/-/g, ' ')}:</p>
                                                        </div>

                                                        <div className="list-content mt-3">
                                                            {message?.message}
                                                        </div>

                                                    </div>

                                                </div>
                                            </li>

                                        </ul>
                                    </div>
                                </div>
                            }

                            <div className="col-12">
                                <div className="category-list">
                                    <ul>
                                        {
                                            categoryList?.map((item) => {
                                                return (
                                                    <li key={item.id} >
                                                        <div className="list-category-main d-flex justify-content-between align-items-center">
                                                            <div className="list-category-left">
                                                                <div className="list-category-head">
                                                                    <p>{item.name}</p>
                                                                </div>
                                                                <div className="list-content mt-3">
                                                                    {
                                                                        item?.osubcatdata.map((subcat) => {
                                                                            return (<p key={subcat.id} className="list-content-item mb-2 mb-xxl-3">{subcat.name}</p>)
                                                                        })
                                                                    }
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </li>
                                                )
                                            })
                                        }
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
            </section>

        </>
    )
}

export default ProfileComponent;