import React, { useEffect, useState, useRef, useMemo } from "react";
import IMAGES from "../../Middleware/images";
import "../../Assets/Styles/organization-list.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import apiHelper from "../../Helper/api-helper";
import { Helmet } from "react-helmet";
import titles from "../../Helper/title";
import DEVELOPMENT_CONFIG from "../../Helper/config";
import ReactLoader from "../../ControlComponents/react-loader";
import "../../Components/pagination";
import Pagination from "../../Components/pagination";

let PageSize = 10;
let PageSize1 = 10;
const style = {
    // position: "absolute",
    // top: "50%",
    // left: "50%",
    // transform: "translate(-50%, -50%)",
    // width: 676,
    // bgcolor: "background.paper",
    // borderRadius: "20px",
    // boxShadow: 24,
    // border: "1px solid #6ED7EF",
    // p: 0,
    // outline: 0,
    // textAlign: "center",
};
const CustomRequest = () => {

    const [currentPage, setCurrentPage] = useState(1);
    const [currentPage1, setCurrentPage1] = useState(1);
    const [organizationList, setOrganizationList] = useState([]);
    const [filterOrganizationList, setFilterOrganizationList] = useState([]);
    const [isLoading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const [search, setSearch] = useState("");
    const [message, setMessage] = useState("");
    const handleOpen = (message) => {
        document.body.classList.add("modal-open");
        setMessage(message)
        setOpen(true)
    };
    const handleClose = () => {
        document.body.classList.remove("modal-open");
        setOpen(false)
    };
    const [activeRow, setActiveRow] = useState(null);
    const tableRef = useRef(null);

    const handleRowClick = (rowId, view) => {

        if (activeRow === rowId) {

            setActiveRow(null);
        } else {

            setActiveRow(rowId);

        }
    }

    const handleDocumentClick = (e) => {
        if (tableRef.current && !tableRef.current.contains(e.target)) {
            setActiveRow(null);

        }
    };
    useEffect(() => {
        document.addEventListener('click', handleDocumentClick);
        return () => {
            document.removeEventListener('click', handleDocumentClick);
        };
    }, []);



    async function getAPI() {
        setLoading(true);
        let result = await apiHelper.getRequest("organization/get-Custom-request");
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setOrganizationList(result?.body.reverse())

            setLoading(false);
        } else {
            setLoading(false);
        }
    }
    function updateIsViewed(index) {
        if (organizationList[index].is_view === "0") {
            setActiveRow(null)
            const updatedList = [...organizationList];
            updatedList[index].is_view = "1";
            setOrganizationList(updatedList); 
        }
    }


    useEffect(() => {
        getAPI()
    }, []);


    const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return organizationList?.slice(firstPageIndex, lastPageIndex);
    }, [currentPage, organizationList]);


    const currentTableData1 = useMemo(() => {
        const firstPageIndex = (currentPage1 - 1) * PageSize1;
        const lastPageIndex = firstPageIndex + PageSize1;
        return filterOrganizationList?.slice(firstPageIndex, lastPageIndex);
    }, [currentPage1, filterOrganizationList]);



    const handleSearch = (e) => {
        let text = e.target.value.toLowerCase();
        setSearch(text);
        const filteredProducts = organizationList?.filter((product) =>
            product?.name?.toLowerCase().includes(text) ||
            product?.email?.toLowerCase().includes(text)
        );
        setFilterOrganizationList(filteredProducts);
    };

    const handleClear = () => {
        setSearch("");
    };

    const refreshFilteredOrganizationList = () => {

        const searchText = search.toLowerCase();
        const filteredProducts = organizationList?.filter((product) =>
            product?.name?.toLowerCase().includes(searchText) ||
            product?.email?.toLowerCase().includes(searchText)
        );
        setFilterOrganizationList(filteredProducts);
    };
    useEffect(() => {
        refreshFilteredOrganizationList();
    }, [organizationList, search]);

    async function getAPI2(id) {
        console.log("first")
        let result = await apiHelper.getRequest(`organization/view-custom-request?id=${id}`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        }
        else {

        }
    }

    return (
        <>
            <Helmet>
                <title>{titles.organization}</title>
            </Helmet>
            <div className="loader">{isLoading && <ReactLoader />}</div>
            <section className="pt-5">
                <div className="container">
                    <div className="row align-items-center pb-4">
                        <div className="col-12 col-lg-5">
                            <div className="list-head mb-2">
                                <h2>List of custom requests</h2>
                            </div>
                        </div>
                        <div className="col-12 col-lg-7">
                            <div className="category-head d-block d-sm-flex justify-content-start justify-content-lg-end align-items-center gap-2 gap-xl-5">
                                <div className="search-category position-relative mb-2 mb-sm-0">
                                    <input
                                        type="serach"
                                        placeholder="Search request..."
                                        value={search}
                                        onChange={(e) => handleSearch(e)}
                                    />
                                    <img
                                        src={IMAGES.SEARCH_ICON}
                                        className="search-icon"
                                        alt=""
                                    />

                                    {search !== "" && <img src={IMAGES.CROSS_1} className="searchCross" style={{ cursor: "pointer" }} onClick={handleClear} />}

                                </div>

                            </div>
                        </div>
                    </div>
                    <div className="row">
                        {search.length <= 0 ? (
                            <>
                                <div className="col-12">
                                    <div className="table-responsive organization-table">
                                        <table className="table mb-0" ref={tableRef}>
                                            <thead>
                                                <tr>
                                                    <th>Serial No.</th>
                                                    <th scope="col">Name</th>
                                                    <th scope="col">Email</th>
                                                    <th scope="col">Mobile Number</th>
                                                    <th scope="col">Requirement</th>
                                                    <th scope="col">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {currentTableData?.map((item, index) => {
                                                    const serialNumber = index + 1 + (currentPage - 1) * PageSize;

                                                    return (
                                                        <tr
                                                            key={item.id}
                                                            onClick={() => {
                                                                handleRowClick(item.id, item.is_view);
                                                                getAPI2(item.id);
                                                                updateIsViewed(index);
                                                            }}
                                                            className={activeRow === item.id || item.is_view == 0 ? 'active' : ''}
                                                        >
                                                            <td
                                                                style={{ cursor: 'pointer' }}
                                                            >
                                                                {serialNumber}
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }}>
                                                                {item?.name}
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }}>
                                                                <div className="table-content">
                                                                    {item.email ? item.email.toLowerCase() : '-'}
                                                                </div>
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }}>
                                                                {item.phone_number}
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }}>
                                                                {item.requirement?.length > 25
                                                                    ? `${item.requirement.slice(0, 25)} ...`
                                                                    : item.requirement
                                                                }
                                                            </td>
                                                            <td style={{ cursor: 'pointer' }}>
                                                                <button
                                                                    className="yellow-btn d-flex align-items-center manage-sub-btn"
                                                                    onClick={() => handleOpen(item.requirement)}
                                                                >
                                                                    View
                                                                </button>
                                                            </td>

                                                        </tr>
                                                    );
                                                })}
                                            </tbody>
                                        </table>

                                    </div>
                                    <div className="row align-items-center mt-2">
                                        <div className="col-6">
                                            <div className="pagination-left">
                                                <nav aria-label="...">
                                                    <ul className="custom-pagination">
                                                        <li className="page-item active" aria-current="page">
                                                            <span className="page-link  cus-page-item">Page</span>
                                                        </li>
                                                        <li className="page-item cus-page-item">
                                                            <a className="page-link"> {currentPage}</a>
                                                        </li>
                                                        <li className="page-item cus-page-item">
                                                            <a className="page-link">of</a>
                                                        </li>
                                                        <li className="page-item cus-page-item">
                                                            <a className="page-link">{Math.ceil(organizationList?.length / PageSize)}</a>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                        <div className="col-6">
                                            <div className="pagination-right">

                                                {organizationList?.length > PageSize &&
                                                    <Pagination
                                                        className="pagination-bar"
                                                        currentPage={currentPage}
                                                        totalCount={organizationList?.length}
                                                        pageSize={PageSize}
                                                        onPageChange={(page) => setCurrentPage(page)}
                                                    />}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </>
                        ) : (
                            <>
                                {filterOrganizationList?.length <= 0 ? (
                                    <p className="notOrganization">No organization found</p>
                                ) : (
                                    <>
                                        <div className="col-12">
                                            <div className="table-responsive organization-table" ref={tableRef}>
                                                <table className="table mb-0">
                                                    <thead>
                                                        <tr>
                                                            <th>Serial No.</th>
                                                            <th scope="col"> Name</th>

                                                            <th scope="col">Email</th>
                                                            <th scope="col">Mobile Number</th>
                                                            <th scope="col">Requirement</th>
                                                            <th scope="col">view</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {currentTableData1?.map((item, index) => {
                                                            const serialNumber = index + 1 + (currentPage1 - 1) * PageSize1;
                                                            return (
                                                                <tr key={item.id} onClick={() => {
                                                                    handleRowClick(item.id)
                                                                    getAPI2(item.id);
                                                                    updateIsViewed(index);
                                                                }

                                                                } className={activeRow === item.id ? 'active' : ''}>
                                                                    <td
                                                                        style={{ cursor: 'pointer' }}
                                                                    >
                                                                        {serialNumber}
                                                                    </td>
                                                                    <td
                                                                        style={{ cursor: "pointer" }}

                                                                    >
                                                                        {item?.name}
                                                                    </td>

                                                                    <td style={{ cursor: "pointer" }}
                                                                    >
                                                                        {item?.email?.toLowerCase() ? item?.email?.toLowerCase() : "-"}

                                                                    </td>
                                                                    <td style={{ cursor: "pointer" }}
                                                                    >
                                                                        <div className="table-content">
                                                                            {item.phone_number}

                                                                        </div>
                                                                    </td>
                                                                    <td style={{ cursor: 'pointer' }}>
                                                                        <td style={{ cursor: 'pointer' }}>
                                                                            {item.requirement?.length > 25
                                                                                ? `${item.requirement.slice(0, 25)} ...`
                                                                                : item.requirement
                                                                            }
                                                                        </td>

                                                                    </td>

                                                                    <td style={{ cursor: 'pointer' }}>
                                                                        <button
                                                                            className="yellow-btn d-flex align-items-center manage-sub-btn"
                                                                            onClick={() => handleOpen(item.requirement)}
                                                                        >
                                                                            View
                                                                        </button>
                                                                    </td>
                                                                </tr>
                                                            );
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="row align-items-center mt-2">
                                                <div className="col-6">
                                                    <div className="pagination-left">
                                                        <nav aria-label="...">
                                                            <ul className="custom-pagination">
                                                                <li className="page-item active" aria-current="page">
                                                                    <span className="page-link  cus-page-item">Page</span>
                                                                </li>
                                                                <li className="page-item cus-page-item">
                                                                    <a className="page-link"> {currentPage1}</a>
                                                                </li>
                                                                <li className="page-item cus-page-item">
                                                                    <a className="page-link">of</a>
                                                                </li>
                                                                <li className="page-item cus-page-item">
                                                                    <a className="page-link">{Math.ceil(filterOrganizationList?.length / PageSize1)}</a>
                                                                </li>
                                                            </ul>
                                                        </nav>
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="pagination-right">

                                                        {filterOrganizationList?.length > PageSize1 &&
                                                            <Pagination
                                                                className="pagination-bar"
                                                                currentPage={currentPage1}
                                                                totalCount={filterOrganizationList?.length}
                                                                pageSize={PageSize1}
                                                                onPageChange={(page) => setCurrentPage1(page)}
                                                            />}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </div>
            </section>
            <div className="loader">{isLoading && <ReactLoader />}</div>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal modal-custom ">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                      <div className="modal-content modal-content-custom">
                    <div
                        onClick={handleClose}
                        className=" position-relative mb-0 text-end pt-3 pe-3"
                        style={{ cursor: "pointer" }}
                    >
                        <img src={IMAGES.CROSS} />
                    </div>
                    <div class="signin-head text-center pb-2"><h1 className="mb-0">Message Detail</h1></div>
                    <div className="pt-2 p-4 custome-message-detail text-center">
                        <p>
                            {message}
                        </p>
                    </div>
                    </div>
                    </div>

                </Box>
            </Modal>


        </>
    );
};

export default CustomRequest;
