import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS} from '../CustomHook/usePagination';
import "../Assets/Styles/pagination.css"
const Pagination = props => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }
  return (
    <ul
      className={classnames('pagination-container-cust ', { [className]: className })}
    >
     {paginationRange.map(pageNumber => {
  if (pageNumber === DOTS) {
    return <li key="dots" className="pagination-item-cust dots-cust">&#8230;</li>;
  }
  return (
    <li
      key={pageNumber}
      className={classnames('pagination-item-cust', {
        selected: pageNumber === currentPage
      })}
      onClick={() => onPageChange(pageNumber)}
    >
      {pageNumber}
    </li>
  );
})}

    </ul>
  );
};

export default Pagination;