import React from 'react'
import IMAGES from '../Middleware/images'
import "../Assets/Styles/footer.css"

const Footer = () => {
    return (
        <>
            <footer className="footer-main">
                <div className="container">
                    <div className="row">
                        <div className="col-6 col-md-4 col-lg-3 text-lg-center">
                            <div className="footer-head">
                                <img src={IMAGES.YELLOW_HAPTAPT} alt="" className="img-fluid footer-head-img" />
                                <p className="footer-logo-text footer-sub-text mt-2">© 2023 haptapt,     LLC</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <div className="footer-subhead mt-4 mt-lg-0">
                                <h4 className='footer-sub-text'>Company</h4>
                                <p className='footer-sub-text footer-hover'  style = {{cursor:"pointer"}} onClick={()=>window.location.href ="https://haptapt.com/"}>About</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <div className="footer-subhead mt-4 mt-lg-0">
                                <h4 className='footer-sub-text'>Resources</h4>
                                <p className='footer-sub-text  footer-hover' style = {{cursor:"pointer"}} onClick={()=>window.location.href ="https://haptapt.com/faqs/"}>FAQs</p>
                            </div>
                        </div>
                        <div className="col-6 col-md-4 col-lg-2">
                            <div className="footer-subhead mt-4 mt-lg-0">
                                <h4 className='footer-sub-text'>Policy</h4>
                                <p className='footer-sub-text  footer-hover' style = {{cursor:"pointer"}} onClick={()=>window.location.href ="https://haptapt.com/haptapt-privacy-policy/"}>Privacy policy</p>
                                <p className='footer-sub-text  footer-hover' style = {{cursor:"pointer"}} onClick={()=>window.location.href ="https://haptapt.com/haptapt-terms-of-service/"}>Terms of use</p>
                            </div>
                        </div>
                       
                        <div className="col-12 col-md-4 col-lg-3 text-lg-center">
                            <div className="footer-subhead mt-4 mt-lg-0">
                                {/* <img src={IMAGES.TWITTER} style = {{cursor:"pointer"}} onClick={()=>window.location.href ="https://twitter.com/haptapt"} alt="" className="footer-icons mb-2 mb-sm-0 mb-lg-2 mb-xl-0" /> */}
                                <img src={IMAGES.INSTAGRAM} style = {{cursor:"pointer"}} onClick={()=>window.location.href ="https://www.instagram.com/haptapt/"} alt="" className="footer-icons mb-2 mb-sm-0 mb-lg-2 mb-xl-0 mx-2" />
                                <img src={IMAGES.LINKEDIN} style = {{cursor:"pointer"}} onClick={()=>window.location.href ="https://www.linkedin.com/company/haptapt/"} alt="" className="footer-icons mb-2 mb-sm-0 mb-lg-2 mb-xl-0"/>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer
