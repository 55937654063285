
import React, { useEffect, useRef, useState } from 'react'
import "../Assets/Styles/header.css";
import IMAGES from '../Middleware/images';
import { Link, NavLink, useNavigate, useLocation } from 'react-router-dom';
import ROUTES from '../Middleware/routes';
import DEVELOPMENT_CONFIG from "../Helper/config"
import apiHelper from '../Helper/api-helper';
import { useQuery } from 'react-query';

const Header = () => {
    const dropdownRef = useRef();
    const [notificationDropdown, setNotificationDropdown] = useState(false);
    const [notifications, setNotifications] = useState([]);
    const navRef = useRef();
    const notificationRef = useRef();
    const iconRef = useRef();
    const navigate = useNavigate()
    const handleLogout = () => {
        localStorage.removeItem("token");
        localStorage.removeItem("role")
        localStorage.removeItem("id")
        localStorage.removeItem("updatedArray")
        navigate(ROUTES.SIGN_IN)
    }

    const handleRouting = () => {
        if (navRef.current.classList.contains("show")) {
            navRef.current.classList.remove("show");
        }
    }
    const closeDropdown = () => {
        setNotificationDropdown(false);
    }

    useEffect(() => {
        const handleOutsideClick = (event) => {
            if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
                closeDropdown();
            }
        }
        document.addEventListener("mousedown", handleOutsideClick);

        return () => {
            document.removeEventListener("mousedown", handleOutsideClick);
        }
    }, []);




    async function getAPI() {
        let result = await apiHelper.getRequest(`organization/get-notification`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setNotifications(result.body)
        }
        else {

        }
    }

    async function getAPI2(id, senderId) {
        let result = await apiHelper.getRequest(`organization/view-notification?id=${id}`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            handleNavigate(senderId)
        }
        else {

        }
    }

    const location = useLocation();

    const isNavLinkActive = (path) => {
        return location.pathname === path;
    };
    const handleNavigate = (id) => {
        setNotificationDropdown(!notificationDropdown)
        navigate(ROUTES.PROFILE_COMPONENT, { state: { id } })
    }

    const { data } = useQuery('notifications', getAPI, { refetchInterval: 5000 })

    return (
        <>
            <nav className={`navbar navbar-expand-lg yellow-back navbar-header`}>
                <div className="container yellow-back">
                    <NavLink to={ROUTES.ORGANIZATION_LIST} className="navbar-brand p-0" ><img src={IMAGES.HAPTAPT_BLACK} className="img-fluid" alt="haptapt-logo" width={178} height={56}/></NavLink>
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div ref={navRef} className="collapse navbar-collapse yellow-back mobile-dropdown" id="navbarNavDropdown"  style={{ alignItems: 'self-start' }}>
						<ul className="navbar-nav ms-auto navbar-item-head">
							{
							location.pathname === "/super-admin" ? (
								<li className="nav-item" onClick={handleRouting} style={{ cursor: "pointer",height:'30px' }}>
								<NavLink to={ROUTES.ORGANIZATION_LIST2} className="nav-link py-3" activeclassname="active" aria-current="page">Organizations</NavLink>
								</li>
							) : (
								<li className="nav-item" onClick={handleRouting} style={{ cursor: "pointer" }}>
								<NavLink to={ROUTES.ORGANIZATION_LIST} className="nav-link py-3" activeclassname="active" aria-current="page">Organizations</NavLink>
								</li>
							)
							}

							<li className="nav-item" style={{ cursor: "pointer" }} onClick={handleRouting}>
							<NavLink to={ROUTES.BILLING} className="nav-link py-3" activeclassname="active">Billing</NavLink>
							</li>
							{/* <li className="nav-item" style={{ cursor: "pointer" }} onClick={handleRouting}>
							<NavLink to={ROUTES.CATEGORY} className="nav-link py-3" activeclassname="active">Categories</NavLink>
							</li> */}
							<li className="nav-item" style={{ cursor: "pointer" }} onClick={handleRouting}>
							<NavLink to={ROUTES.CUSTOM} className="nav-link py-3" activeclassname="active">Custom request</NavLink>
							</li>
						
						</ul>
                        <ul className='pe-3 mobile-dropdown d-mobile-dropdowns' style={{ position: 'relative', top: '10px' }}>
							<li className="nav-item me-2">
								<div className="dropdown notification-dropdown position-relative" ref={dropdownRef}>
									<div ref={iconRef} onClick={() => setNotificationDropdown(!notificationDropdown)}>
									<img src={IMAGES.NOTIFICATION} className="img-fluid" alt="notifications" />
									</div>
									{notificationDropdown && (
									<div
										ref={notificationRef}
										className={notifications.length > 0 ? "notification-dropdown-menu dropdown-menu show droptrangle" : "notification-dropdown-menu dropdown-menu show droptrangle dropnotify"}
									>
										<ul>
										{notifications.length > 0 ? (
											notifications.slice().reverse().map(notification => (
											<li key={notification.id} onClick={() => getAPI2(notification.id, notification.sender_id)}>
												<Link className={notification.is_view === 0 ? "dropdown-item tapped-drop-item notificition-drop-items active" : "dropdown-item tapped-drop-item notificition-drop-items"}>
												<div className="notify-profile-list d-flex align-items-center gap-3" onClick={() => navigate(handleNavigate(notification.sender_id))}>
													<div className="notify-profile-head">
													<span className="notify-profile" style={{ textTransform: "uppercase" }}>{notification?.message?.split(' ')[1]?.substr(0, 2)}</span>
													</div>
													<div className="notify-text">
													<p>{notification.message}<br />
														<div className='d-flex align-items-center'>
														{new Date(notification.created_at).toLocaleString('en-US', { month: 'short', day: 'numeric', year: 'numeric' })}
														<span className='dot-span'></span>
														<span>{new Date(notification.created_at).toLocaleTimeString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}</span>
														</div>
													</p>
													</div>
												</div>
												</Link>
											</li>
											))
										) : (
											<li className='px-4'>No notifications.</li>
										)}
										</ul>
									</div>
									)}
								</div>
							</li>
							<li className="nav-item">
							<div className="dropdown nav-link position-relative">
								<a className="" role="button" data-bs-toggle="dropdown" aria-expanded="false">
								<img
									src={isNavLinkActive(ROUTES.PROFILE) ? IMAGES.ACTIVE_USER : IMAGES.USER_PROFILE}
									alt="user"
								/>
								</a>
								<ul className="dropdown-menu notify-dropdown notify-dropdown-custom"  style={{ left: 'auto',top:'138%' }}>
								<li onClick={() => {
									navigate(ROUTES.PROFILE);
									if (navRef.current.classList.contains("show")) {
									navRef.current.classList.remove("show");
									}
								}}>
									<Link className="dropdown-item dropdown-item-new nav-link" style={{ borderBottom: "1px solid #6ed7ef" }}>
									<div className="notify-profile-list d-flex align-items-center gap-3">
										<Link to={ROUTES.PROFILE} className="nav-link">Profile</Link>
									</div>
									</Link>
								</li>
								<li onClick={handleLogout}>
									<Link className="dropdown-item dropdown-item-new">
									<div className="notify-profile-list d-flex align-items-center gap-3">
										<Link to={ROUTES.SIGN_IN} className="nav-link">Logout</Link>
									</div>
									</Link>
								</li>
								</ul>
							</div>
							</li>
						</ul>
					</div>

                </div>
            </nav>
        </>
    )
}

export default Header;
