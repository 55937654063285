import React, { useEffect } from 'react'
import Header from './Components/header'
import HeaderOrganization from "./Components/header-admin";
import HeaderUser from "./Components/header-user"
import { useLocation } from 'react-router-dom';
import Footer from './Components/footer';

function
  Layout({ children }) {
  const location = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname]);
  return (
    <>
      {
        localStorage.getItem("role") == 1 && <HeaderUser />
      }
      {
        localStorage.getItem("role") == 2 && <HeaderOrganization />

      }
      {
        localStorage.getItem("role") == 3 && <Header />
      }
      <main className='main-heigth'>{children}</main>
      <Footer />
    </>
  )
}
export default Layout