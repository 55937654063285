import * as React from 'react';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import { Chip } from '@mui/material';
import { useState } from 'react';
import { useMemo } from 'react';
import { useEffect } from 'react';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

export default function CategoryComponent(props) {
    const [categories, setCategories] = React.useState([]);
    const [values, setValues] = React.useState([]);
    const [inputValue, setInputValue] = React.useState('');
    const [openDropdown, setOpenDropdown] = useState(false);
    const inputRef = React.useRef(null);

    React.useEffect(() => {
        setCategories(props.subCategoryList.osubcatdata.map(item => ({ name: item.name, id: item.id })))
    }, []);

    const handleInputChange = (event, newInputValue) => {
        props.setErrorMessage("");
        setInputValue(newInputValue);
        setOpenDropdown(true); 
        inputRef.current.focus()
    };

    const handleSelectChange = (event, newValue) => {
        props.setErrorMessage("");
        setValues(newValue);
        props.saveValuesToLocalStorage(newValue);
        setInputValue('');
    };

    const handleKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ',' || event.key === "NumpadEnter") {
            event.preventDefault();
            props.setErrorMessage("");
            if (inputValue.trim() !== '') {
                const selectedValue = categories.find(e => {
                    let inputValueOne = inputValue.trim().split(',')[0]
                    if (e.name.toLowerCase() == inputValueOne.toLowerCase()) {
                        return e.id
                    }
                }
                )
                let updatedValues
                if (selectedValue !== undefined) {
                    const newValues = inputValue.trim().split(',').map(value => (selectedValue));
                    updatedValues = [...values, ...newValues];
                    setValues(updatedValues);
                    props.saveValuesToLocalStorage(updatedValues);
                    setInputValue('');
                    inputRef.current.focus()
                    setOpenDropdown(true)
                } else {
                    const newValues = inputValue.trim().split(',').map(value => ({ name: value, id: 'user_1' }));
                    updatedValues = [...values, ...newValues];
                    setValues(updatedValues);
                    props.saveValuesToLocalStorage(updatedValues);
                    setInputValue('');
                    inputRef.current.focus()
                    setOpenDropdown(true)
                }


            }
        }
    };

    const handleCheckboxChange = (option) => (event) => {
        setOpenDropdown(true);
        props.setErrorMessage("");
        const isChecked = event.target.checked;
        setValues(prevValues => {
            let updatedValues = [];
            if (isChecked && !prevValues.find(value => value.id === option.id)) {
                updatedValues = [...prevValues, option];
            } else if (!isChecked) {
                updatedValues = prevValues.filter(value => value.id !== option.id);
            } else {
                updatedValues = prevValues;
            }
            props.saveValuesToLocalStorage(updatedValues);
            return updatedValues;
        });
    };

    return (
        <Autocomplete
            multiple
            id="checkboxes-tags-demo"
            options={categories}
            value={values}
            inputValue={inputValue}
            open={openDropdown}
            onInputChange={handleInputChange}
            disableCloseOnSelect
            getOptionLabel={(option) => option.name}
            renderOption={(props, option, { selected }) => (
                <li {...props}>
                    <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                        onChange={handleCheckboxChange(option)}
                    />
                    {option.name}
                </li>
            )}
            style={{ width: 500 }}
            disableInput
            renderInput={(params) => (
                <TextField {...params} label={props?.subCategoryList?.name} onKeyDown={handleKeyDown}
                    onFocus={() => setOpenDropdown(true)}
                    onBlur={() => setOpenDropdown(false)}
                    onChange={(e) => {
                        props.setErrorMessage("")
                        const value = e.target.value;
                        setOpenDropdown(categories.some(item => item === value));
                    }}
                    inputRef={inputRef}
                    InputProps={{ ...params.InputProps, inputProps: { ...params.inputProps, inputMode: 'search' } }}
                />
            )}
            className='custom-autocomplete'
            onChange={handleSelectChange}
        />
    );
}
