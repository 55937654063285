const ROUTES = {
  SIGN_IN: "/",
  EMAIL_PROFILE:"/profile-view",
  NO_DATA: "/no-data",
  SIGN_UP: "/signup",
  FORGOT_PASSWORD: "/forgot-password",
  ORGANIZATION_LIST2: "/super-admin",
  ORGANIZATION_LIST: "/super-admin/organizations",
  ORGANIZATION_MEMBER: "/super-admin/organizations/organization-detail",
  BILLING: "/super-admin/billing",
  CATEGORY: "/super-admin/category",
  PROFILE: "/super-admin/profile",
  ERROR_PAGE: "/verify-account",
  CUSTOM: "/custom-request",
  SEARCH_EMPLOYEES2: "/admin",
  SEARCH_EMPLOYEES: "/admin/search-employees",
  PROFILE_CONFIRMATION: "/admin/profile-confirmation",
  SEARCH_EMPLOYEES_RESULT: "/admin/search-employees/search-employees-result",
  EMPLOYEES_LIST: "/admin/employees",
  EMPLOYEE_PROFILE: "/admin/employees/employee-profile",
  SETTING_BILLING: "/admin/setting-billing",
  PROFILE_COMPONENT:"/profile",
  ORGANIZATION_CATEGORY: "/admin/organization-category",
  ORGANIZATION_SETTING: "/admin/profile-settings",
  TAPPED_MEMBER: "/admin/search-employees/search-employees-result/tapped-member",
  PAYMENT: "/admin/setting-billing/payment",
  SEARCH_DATABASE2: "/user",
  SEARCH_DATABASE: "/user/search-database",
  SEARCH_RESULT: "/user/search-database/search-result",
  USER_PROFILE: "/user/profile",
  TAPPED_MEMBER_USER: "/user/search-database/search-result/tapped-member",
}

export default ROUTES;