import { Autocomplete, Chip, TextField } from "@mui/material";
import React, { useRef, useState } from "react";
import ClearIcon from "@mui/icons-material/Clear";

const RoleInput = (props) => {
  const [openDropdown, setOpenDropdown] = useState(false);

  const handleKeyDownCapture = (e) => {
    if ((e.code === "Enter" || e.code === "NumpadEnter") && e.target.value.trim().length === 0 && e.target.value.trim().length !== 0) {
      const newRole = e.target.value.trim();
      if (!props.fields.roleArr.includes(newRole)) {
        props.setFields({
          ...props.fields,
          roleArr: [...props.fields.roleArr, newRole],
          role: ""
        });
      }
    } else if ((e.key === "," || e.code === ",") && e.target.value.trim().length !== 0) {

      const newRole = e.target.value.trim();
      if (!props.fields.roleArr.includes(newRole)) {
        props.setFields({
          ...props.fields,
          roleArr: [...props.fields.roleArr, newRole],
          role: ""
        });
      }
    }
  };

  const handleChange = (e, fieldName) => {
    props?.setErrors("");
    const name = e.target.name;
    const value = e.target.value;
    props?.setSelectedField(fieldName);
    props?.setErrorMessage("");
    props?.setFields({
      ...props?.fields,
      [name]: value.replace(/[^a-zA-Z0-9\s]+/g, "")
    });
    if (e.target.value === "") {
      setOpenDropdown(false);
    } else {
      setOpenDropdown(true);
    }
  };



  return (
    <>
      <Autocomplete
        clearIcon={
          <ClearIcon
            onClick={(e) => {
              props?.setFields({
                ...props?.fields,
                roleArr: [],
                role: ""
              });
              setOpenDropdown(false);
              props?.setErrors("");
            }}
            fontSize="small"
          />
        }
        multiple
        inputValue={props?.fields?.role}
        id="tags-filled"
        options={props?.roleList.filter((option) => !props.fields.roleArr.includes(option))}
        defaultValue={props?.fields.roleArr || []}
        freeSolo
        value={props?.fields.roleArr || []}

        open={openDropdown}
        onInputChange={(event, newInputValue) => {
          if (newInputValue.trim() !== "") {
            setOpenDropdown(true);
          } else {
            setOpenDropdown(false);
          }
        }}

        onChange={(event, newValue) => {
          props?.setErrors("");
          props?.setFields({
            ...props?.fields,
            roleArr: newValue,
            role: ""
          });
          setOpenDropdown(false);
        }}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              sx={{
                backgroundColor: "#EBFBFF",
                color: "#231F20",
                "& .MuiChip-deleteIcon": {
                  color: ""
                }
              }}
              label={option}
              {...getTagProps({ index })}
            />
          ))
        }
        renderInput={(params) => (
          <TextField
            {...params}
            sx={{
              "& fieldset": { border: "2px solid #231f20", borderRadius: "30px" },
              "& .MuiInputLabel-shrink": { color: "#231f20;" }
            }}
            label={props?.labelObj.role}
            onFocusCapture={() => {
              props?.setLabelObj({
                ...props?.labelObj,
                role: "Role"
              });
              setOpenDropdown(false);
            }}
            onChange={(e) => handleChange(e, "role")}
            name="role"
            value={props?.fields.role}
            onBlur={(e) => {
              setOpenDropdown(false);
              const enteredRole = e.target.value.trim();
              if (enteredRole && !props?.fields.roleArr.includes(enteredRole)) {
                props?.setFields({
                  ...props?.fields,
                  roleArr: [...props?.fields.roleArr, enteredRole],
                  role: ''
                });
              }

              if (props?.fields.roleArr.length === 0) {
                props?.setLabelObj({
                  ...props?.labelObj,
                  role: "Role (i.e., Account Manager)"
                });
              } else if (enteredRole && !props?.fields.roleArr.includes(enteredRole)) {
                props?.setLabelObj({
                  ...props?.labelObj,
                  role: "Role"
                });
              }
            }}
            onKeyDownCapture={handleKeyDownCapture}
          />
        )}
      />
    </>
  );
};

export default RoleInput;
