import React, { useEffect, useState,useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IMAGES from '../../Middleware/images';
import "../../Assets/Styles/profile.css";
import ClearIcon from '@mui/icons-material/Clear';
import ERR_MESSAGE from '../../Helper/error-helper';
import apiHelper from '../../Helper/api-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import Chip from '@mui/material/Chip';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import titles from '../../Helper/title';

import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
const libraries = ['places'];
const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 676,
    // bgcolor: 'background.paper',
    // borderRadius: "20px",
    // boxShadow: 24,
    // border: "1px solid #6ED7EF",
    // p: 0,
    // outline: 0,
    // textAlign: "center",
};

const theme = createTheme({
    overrides: {
        MuiOutlinedInput: {
            root: {
                '& $notchedOutline': {
                    borderColor: 'red', 
                },
                '&:hover:not($disabled):not($focused):not($error) $notchedOutline': {
                    borderColor: 'your_custom_hover_color', 
                },
            },
        },
    },
});

const Profile = () => {
    const [open, setOpen] = useState(false);
    const [isLoading, setLoading] = useState(false);
    const [editOpen, setEditOpen] = React.useState(false);
    const [openChangePassword, setOpenChangePassword] = useState(false);
    const [eye_icon1, setEye_icon1] = useState(IMAGES.EYE_HIDE);
    const [type1, setType1] = useState("password");
    const [msg1, setMsg1] = useState("reveal password")
    const [eye_icon2, setEye_icon2] = useState(IMAGES.EYE_HIDE);
    const [type2, setType2] = useState("password");
    const [msg2, setMsg2] = useState("reveal password")
    const [eye_icon3, setEye_icon3] = useState(IMAGES.EYE_HIDE);
    const [type3, setType3] = useState("password");
    const [msg3, setMsg3] = useState("reveal password")
    const [fields1, setFields1] = useState({
        oldPassword: "",
        newPassword1: "",
        newPassword2: "",
        email: "",
        name: "",
        location: "",
        roleTitle: "",
        category: "",
        subCat: "",
        subCatArr: [],
        longitude:"",
        latitude:"",
    })

 
    const [openDelete, setOpenDelete] = useState(false);
    const [deltId, setDeltId] = useState(null);
    const handleDeleteOpen = (id) => {
        document.body.classList.add("modal-open");

        setDeltId(id)
        setOpenDelete(true);
    }

    const handleDeleteClose = () => {
        document.body.classList.remove("modal-open");

        setOpenDelete(false);
        setDeltId(null)
    }
    async function getDeleteAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest(`auth/delete-category?id=${deltId}`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setLoading(false)
            success(result.message)
            handleDeleteClose()
            getAPI()
        }
        else {
            error(result.message)
            setLoading(false)
        }
    }
    const [categoryList, setCategoryList] = useState([]);
    const [editId, setEditId] = useState([]);
    const [superAdminDetail, setSuperAdminDetail] = useState("");
    const navigate = useNavigate()

    const handleOpen = () => {
        document.body.classList.add("modal-open");
        setFields1({
            ...fields1,
            latitude:superAdminDetail.latitude,
            longitude:superAdminDetail.longitude,
            name: superAdminDetail.name,
            place: superAdminDetail?.location,
            email: superAdminDetail.email,
            roleTitle: superAdminDetail.designation == null? "Super Admin": superAdminDetail.designation,
        })
        setOpen(true);
    }
    const handleClose = () =>{ 
        document.body.classList.remove("modal-open")
        setErrors("")
        setOpen(false);}


    const handleEditOpen = (id, catName, subcatdata) => {
        document.body.classList.add("modal-open");

        setEditId(id);
        setEditOpen(true);
        setFields1({
            ...fields1,
            category: catName,
            subCat: "",
            subCatArr: subcatdata.map((item) => item.name)
        })
    }

    const handleEditClose = () => {
        document.body.classList.remove("modal-open");

        setFields1(
            {
                ...fields1,
                category: "",
                subCat: "",
                subCatArr: []
            }
        )
        setEditOpen(false);
    }

    const handleChangePasswordOpen = () => {
        document.body.classList.add("modal-open");
        setOpenChangePassword(true);}

    const handleChangePasswordClose = () => {
        document.body.classList.remove("modal-open");

        setFields1(
            {
                ...fields1,
                oldPassword: "",
                newPassword1: "",
                newPassword2: "",
            }
        )
        setErrors("");
        setOpenChangePassword(false)
    };

    const [errors, setErrors] = useState("");
    const [fields, setFields] = useState({
        email: "",
    });

    const showOldPassword = () => {
        if (type3 === "password") {
            setType3("text");
            setEye_icon3(IMAGES.EYE_SHOW);
            setMsg3("hide password")
        }
        else {
            setType3("password");
            setEye_icon3(IMAGES.EYE_HIDE);
        }
    }
    const showPassword = () => {
        if (type1 === "password") {
            setType1("text");
            setEye_icon1(IMAGES.EYE_SHOW);
            setMsg1("hide password")
        }
        else {
            setType1("password");
            setEye_icon1(IMAGES.EYE_HIDE);
        }
    }
    const showConfirmPassword = () => {
        if (type2 === "password") {
            setType2("text");
            setEye_icon2(IMAGES.EYE_SHOW);
            setMsg2("hide password")
        }
        else {
            setType2("password");
            setEye_icon2(IMAGES.EYE_HIDE);
        }
    }
    const inputRef = useRef(null); 
    let key = "AIzaSyDFkw6-U8mAQEGTOC77ZYrIojD4isGoNgg";

const handlePlaceChanged = () => {
    setErrors("");
    const [place] = inputRef?.current?.getPlaces();

    if (place) {
        setFields1({
            ...fields1,
            place: place.formatted_address,
            latitude: `${place.geometry.location.lat()}`,
            longitude: `${place.geometry.location.lng()}`,
        });
    } 
};

const handleChange = (e) => {
    setErrors("");

    const name = e.target.name;
    const value = e.target.value;

    if(name === "place"){
        setFields1({
        ...fields1,
        [name]: value,
        latitude: "",
        longitude: ""
    });
    }
    else{
        setFields1({
            ...fields1,
            [name]: value,
        });
    }
   
};

    const handleChange2 = (e) => {
        setErrors("");
        const name = e.target.name;
        const value = e.target.value;
        setFields1({
            ...fields1,
            [name]: value,
        });
    };

    const handleValidation = () => {
        let errors = {};
        let formIsValid = true;
        let passwordReg = /^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*\W)(?!.* ).{8,}$/;
        const { oldPassword, newPassword1, newPassword2 } = fields1;

        if (oldPassword.trim() == "") {
            formIsValid = false
            errors["oldPassword"] = ERR_MESSAGE.OLD_PASSWORD_EMPTY
        } else if (passwordReg.test(oldPassword) === false) {
            formIsValid = false
            errors["oldPassword"] = ERR_MESSAGE.INVALID_PASSWORD
        }
        if (newPassword1.trim() == "") {
            formIsValid = false
            errors["newPassword1"] = ERR_MESSAGE.NEW_PASSWORD_EMPTY
        }
         else if (passwordReg.test(newPassword1) === false) {
            formIsValid = false
            errors["newPassword1"] = ERR_MESSAGE.INVALID_PASSWORD
        }
        if (newPassword2.trim() == "") {
            formIsValid = false
            errors["newPassword2"] = ERR_MESSAGE.CONFIRM_PASSWORD_EMPTY
        }
        else if (newPassword2.trim() !== newPassword1.trim()) {
            formIsValid = false
            errors["newPassword2"] = ERR_MESSAGE.NOTMATCHED
        }
        setErrors(errors);
        return formIsValid;
    };


    const handleValidation2 = () => {
        let errors = {};
        let formIsValid = true;

        const { category, subCatArr } = fields1;
        if (!category || category.trim().length === 0) {
            formIsValid = false;
            errors["category"] = ERR_MESSAGE.CATEGORY_EMPTY
        }
        // else if (!subCatArr || subCatArr.length === 0) {
        //     formIsValid = false;
        //     errors["subcatArr"] = ERR_MESSAGE.OPTIONS_EMPTY
        // }
        setErrors(errors);
        return formIsValid;
    };

    const handleValidationProfile = () => {
        let errors = {};
        let formIsValid = true;
        const emojiSymbolRegex = /[^\p{L}\s]/u;

        let reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w\w+)+$/;
        const { email, name, place, longitude,latitude,roleTitle } = fields1;
        const emojiRegex = /[\uD800-\uDBFF][\uDC00-\uDFFF]|[\u2600-\u27FF]|[\uD83C][\uDF00-\uDFFF]|[\uD83D][\uDC00-\uDE4F]/g;
        if (!name || name.trim().length === 0) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.EMPTY_NAME
        } else if (name.length < 3) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME_MIN
        }
        else if (name.length >30) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME_MAX
        }
        else if (emojiRegex.test(name)) {
            formIsValid = false;
            errors["name"] = ERR_MESSAGE.INVALID_NAME;
          }
        if (!email || email.trim().length === 0) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_EMPTY
        }
        else if (reg.test(email.toLowerCase()) === false) {
            formIsValid = false;
            errors["email"] = ERR_MESSAGE.EMAIL_INVALID
        }
         if (!place || place.trim().length === 0) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.LOCATION_EMPTY
        }
        else if (!longitude || longitude?.trim().length === 0 || !latitude || latitude?.trim().length === 0) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.INVALID_LOCATION;
          }
          else if (emojiRegex.test(place)) {
            formIsValid = false;
            errors["place"] = ERR_MESSAGE.EMOJI;
          }
          if (!roleTitle  || roleTitle.trim().length === 0) {
            formIsValid = false;
            errors["roleTitle"] = ERR_MESSAGE.ROLE_EMPTY
        }
        setErrors(errors);
        return formIsValid;
    };
    const success = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }
    const error = (msg) => {
        toast.success(msg,
            {
                autoClose: 5000,
            });
    }

    async function postAPI(e) {
        e.preventDefault();
        if (handleValidation()) {
            let data = JSON.stringify({
                "oldPassword": fields1.oldPassword,
                "newPassword": fields1.newPassword2,
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/change-passsword", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleChangePasswordClose()
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    } 

    async function postAPIEditProfile(e) {
        let id = localStorage.getItem("id")
        e.preventDefault();
        if (handleValidationProfile()) {
            let data = JSON.stringify({
                "name": fields1.name,
                "designtion": fields1.roleTitle,
                "id": id,
                "location": fields1.place,
                "latitude": fields1.latitude,
                "longitude":fields1.longitude
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/set-your-profile", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleClose();
                getMyDetailAPI();
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }
    async function postAPIEditCat(e) {
        e.preventDefault();
        if (handleValidation2()) {
            let data = JSON.stringify({
                "cat_id": editId,
                "name": fields1.category,
                "subcatArr": fields1.subCatArr
            });
            setLoading(true);
            let result = await apiHelper.postRequest("auth/edit-category", data)
            if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
                setLoading(false);
                success(result.message)
                handleEditClose();
                getAPI()
            }
            else {
                error(result.message)
                setLoading(false)
            }
        }
    }

    async function getMyDetailAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest("auth/get-my-detail")
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setSuperAdminDetail(result.body)

            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }
    let id = localStorage.getItem("id")
    async function getAPI() {
        setLoading(true)
        let result = await apiHelper.getRequest(`auth/admin-category-list?id=${id}`)
        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
            setCategoryList(result.body)
            setLoading(false)
        }
        else {
            setLoading(false)
        }
    }

    useEffect(() => {
        getMyDetailAPI();
        getAPI();
    }, [])

    return (
        <>
            <Helmet>
                <title>{titles.profile}</title>
            </Helmet>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className="pt-5">
                <div className="container">
                    <div className="row align-items-center pb-4">
                        <div className="col-12 col-lg-4">
                            <nav>
                                <ul className="d-flex gap-2 align-items-center mb-0">
                                    <li style={{ cursor: "pointer" }} onClick={() => navigate(-1)}><img src={IMAGES.BACK_ARROW} alt="" /></li>
                                    <li><a>{superAdminDetail.name}</a></li>
                                </ul>
                            </nav>
                        </div>
                      
                    </div>
                    <div className="row">
                        <div className="col-12 col-xl-3">
                            <div className="profile-heading">
                                <h2>Your Profile</h2>
                            </div>
                            <div className="company-info back-shadow">
                                <div className="p-4">
                                    <div className="company-section-sub-head d-flex justify-content-between py-3 ps-xxl-4 pe-xxl-0 align-items-center">
                                        <h2 className="company-info-head mb-0">{superAdminDetail.name}
                                            <p className="user-professtion"> </p></h2>
                                        <img onClick={handleOpen} style={{ cursor: "pointer" }} src={IMAGES.EDIT_ICON} alt="edit-icon" className="img-fluid" />
                                    </div>
                                    <ul className="sub-company-list mb-2">
                                        <li className="sub-company-list-item">
                                            <label className="sq-radio">
                                                <input type="checkbox" checked="checked" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className='label-profile'>
                                            <p className="pe-1 label-head">Email :</p>
                                            <p className="fw-900 one-line-text text-cus-align">{superAdminDetail.email}</p>
                                            </div>
                                        </li>
                                        <li className="sub-company-list-item">
                                            <label className="sq-radio">
                                                <input type="checkbox" checked="checked" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className='label-profile'>
                                            <p className="pe-1 label-head">Location :</p>
                                            <p className="fw-900 one-line-text text-cus-align">{superAdminDetail.location}</p>
                                       </div>
                                        </li>
                                        <li className="sub-company-list-item">
                                            <label className="sq-radio">
                                                <input type="checkbox" checked="checked" name="radio" />
                                                <span className="checkmark"></span>
                                            </label>
                                            <div className='label-profile'>
                                            <p className="pe-1 label-head">Role :</p>
                                            <p className="fw-900 one-line-text text-cus-align">Super admin</p>
                                      </div>
                                        </li>
                                    </ul>
                                    <div className="mx-auto text-center" style={{ cursor: "pointer" }}>
                                        <div onClick={handleChangePasswordOpen} className="company-info-btns position-relative mt-4">
                                            <button type="button" className="yellow-btn add-category-btn d-flex align-items-center yellow-change-btn">Change Password <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-12 col-xl-9 pt-4 pt-xl-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="category-list">
                                        <ul>
                                            {
                                                categoryList.map((item) => {
                                                    return (
                                                        <li key={item.id} >
                                                            <div className="list-category-main d-flex justify-content-between align-items-center">
                                                                <div className="list-category-left">
                                                                    <div className="list-category-head">
                                                                        <p>{item.name}</p>
                                                                    </div>
                                                                    <div className="list-content mt-3">
                                                                        {
                                                                            item.subcatdata.map((subcat) => {
                                                                                return (<p key={subcat.id} className="list-content-item mb-2 mb-xxl-3">{subcat.name}</p>)
                                                                            })
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div style={{ cursor: "pointer" }} className="list-category-right">
                                                                <img onClick={() => handleDeleteOpen(item.id)} src={IMAGES.TRASH_ICON} alt="delete-icon" style={{ cursor: "pointer" }} className="img-fluid" />
                                                                    <img onClick={() => handleEditOpen(item.id, item.name, item.subcatdata)} src={IMAGES.EDIT_ICON} alt="edit-icon" className="img-fluid" />
                                                                </div>
                                                            </div>
                                                        </li>
                                                    )
                                                })
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <Modal
                open={openDelete}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
             > 
                <Box sx={style} className="modal modal-custom">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                  <div className="modal-content modal-content-custom">
                    <div onClick={handleDeleteClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                        <img src={IMAGES.CROSS} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h2>delete category</h2>
                    </div>
                    <div className="mb-4  position-relative">
                        <p>
                            Are you sure you want to delete the category name and its associated tags?
                        </p>
                    </div>
                    <div className="mb-5 text-center position-relative submit-head">
                        <button type="submit" className="yellow-btn d-flex align-items-center" onClick={getDeleteAPI}>Delete <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                    </div>
                    </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={open}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal modal-custom">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                  <div className="modal-content modal-content-custom">
                    <div onClick={handleClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                        <img src={IMAGES.CROSS} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h1 >edit profile</h1>
                    </div>
                    <form onSubmit={postAPIEditProfile}>
                        <div className="mb-5 position-relative mx-5">
                        <TextField fullWidth label="Your name" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="name" onChange={handleChange}  value={fields1.name} />
                         {errors.name &&    <span className="err_msg">{errors.name}</span>}
                        </div>
                        <div className="mb-5 position-relative mx-5">
                            <label className="form-label email-label mb-0 email-input">Email address</label>
                            <input type="text" name="email" onChange={handleChange} error={errors.email}
                                value={fields1.email} className="form-control comm-radius comm-input" disabled />
                       { errors.email &&    <span className="err_msg">{errors.email}</span>}
                        </div>
                        <div className="mb-5 position-relative mx-5">
                        <LoadScript
                                googleMapsApiKey={key}
                                libraries={libraries}
                            >
                                <StandaloneSearchBox
                                ref={inputRef}
                                onLoad={(ref) => (inputRef.current = ref)}
                                onPlacesChanged={handlePlaceChanged}
                                >
                                <TextField
                                    fullWidth
                                    label="Location"
                                    variant="outlined"
                                    sx={{
                                    "& fieldset": {
                                        border: "2px solid #231f20",
                                        borderRadius: "30px",
                                    },
                                    "& .MuiInputLabel-shrink": {
                                        color: "#231f20;",
                                    },
                                    }}
                                    name="place"
                                    onChange={handleChange}
                                    value={fields1.place}
                                    placeholder="Enter location"
                                />
                                </StandaloneSearchBox>
                            </LoadScript>
                            {errors?.place && <span className="err_msg">{errors.place}</span>}  </div>
                        <div className="mb-5 position-relative mx-5">
                        <TextField fullWidth label=" Role/Title" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="roleTitle" onChange={handleChange}  value={fields1.roleTitle} />
                      {  errors.roleTitle &&    <span className="err_msg">{errors.roleTitle}</span>
}                        </div>
                        <div className="mb-5 text-center position-relative submit-head">
                            <button type="submit" className="yellow-btn d-flex align-items-center">Save <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                        </div>
                    </form>
                    </div>
                    </div>
                </Box>
            </Modal>
            <Modal
                open={openChangePassword}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal modal-custom">
                <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                  <div className="modal-content modal-content-custom">
                    <div onClick={handleChangePasswordClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                        <img src={IMAGES.CROSS} />
                    </div>
                    <div className="signin-head text-center pb-3">
                        <h1>change password</h1>
                    </div>
                    <form onSubmit={postAPI}>
                        <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                        <TextField type={type3} fullWidth label="Old password" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                          }}
                      name="oldPassword" onChange={handleChange2}  value={fields.oldPassword} />
                           
                            <img src={eye_icon3} alt="" onClick={showOldPassword} className="hiden-eye" title={msg3} />
                          {errors.oldPassword &&  <span className="err_msg">{errors.oldPassword}</span>}
                        </div>
                        <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                        <TextField type={type1} fullWidth label="new password" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                          }}
                      name="newPassword1" onChange={handleChange2}  value={fields.newPassword1} />
                           
                            <img src={eye_icon1} alt="" onClick={showPassword} className="hiden-eye" title={msg1} />
                        { errors.newPassword1 &&   <span className="err_msg">{errors.newPassword1}</span>}
                        </div>
                        <div className="editbottom position-relative mx-4 mx-sm-5 forgot-input">
                        <TextField type={type2} fullWidth label="new password" variant="outlined"
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                          }}
                      name="newPassword2" onChange={handleChange2}  value={fields.newPassword2} />
                            <img src={eye_icon2} alt="" onClick={showConfirmPassword} className="hiden-eye" title={msg2}/>
                         { errors.newPassword2 &&  <span className="err_msg">{errors.newPassword2}</span>}
                        </div>
                        <div className="editbottom text-center position-relative submit-head">
                            <button type="submit" className="yellow-btn d-flex align-items-center">Save <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                        </div>
                    </form>
                    </div>
                    </div>
                </Box>
            </Modal>

            <ThemeProvider theme={theme}>
                <Modal
                    open={editOpen}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style} className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                      <div className="modal-content modal-content-custom">
                     <div onClick={handleEditClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                            <img src={IMAGES.CROSS} />
                        </div>
                        <div className="signin-head text-center pb-3">
                            <h1>Edit Category</h1>
                        </div>
                        <div className="mb-4  position-relative px-3">
                            <p>
                                Please modify the category name and its associated options for input on the platform.                        </p>
                        </div>
                        <form onSubmit={postAPIEditCat} >
                            <div className="editbottom position-relative mx-4 mx-sm-5">
                            <TextField fullWidth label="Enter category name" variant="outlined"
                        
                        sx={{
                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                        }}
                    name="category" onChange={handleChange}  value={fields1.category} />
                        {    errors.category &&    <span className="err_msg">{errors.category}</span>}
                            </div>

                            <div className="editbottom position-relative mx-4 mx-sm-5">

                                <Autocomplete
                                 clearIcon={<ClearIcon 
                                    onClick={(e)=>{
                                        setFields1({
                                            ...fields1,
                                            subCatArr:[],
                                            subCat:""
                                        });
                                        setErrors("")
                                }} 
                                fontSize='small'/>}
                                    multiple
                                    options={[]}
                                    name='subCatArr'
                                    defaultValue={fields1.subCatArr}
                                    value={fields1.subCatArr}
                                    onChange={(event, newValue) => {
                                        setFields1({
                                            ...fields1,
                                            subCatArr: newValue,
                                        });
                                    }}
                                    inputValue={fields1.subCat}
                                    freeSolo
                                    renderTags={(value, getTagProps) =>
                                        value.map((option, index) => (
                                            <Chip label={option}
                                                sx={{
                                                    backgroundColor: "#EBFBFF",
                                                    color: "#231F20",
                                                    "& .MuiChip-deleteIcon": {
                                                        color: ""
                                                    }
                                                }}
                                                {...getTagProps({ index })} />
                                        ))
                                    }
                                    renderInput={(params) => (
                                        <TextField 
                                        fullWidth 
                                        {...params}
                                        label="Enter options" variant="outlined"
                                        onBlur={(e)=>{  
                                            if(e.target.value.trim().length!=0){
                                                setFields1({
                                                    ...fields1,
                                                    subCatArr: [...fields1.subCatArr, e.target.value],
                                                    subCat:""
                                                })
                                            }
                                            return;
                                           
                                        }}
                                        onKeyDownCapture={(e)=>{
                                            if(e.code == "Enter" || e.code == "NumpadEnter"|| e.key === ","){
                                                if(e.target.value.trim().length!=0){
                                                    e.preventDefault();
                                                    setFields1({
                                                        ...fields1,
                                                        subCatArr: [...fields1.subCatArr, e.target.value],
                                                        subCat:""
                                                    })
                                                }
                                                else if(e.target.value.trim().length == 0){
                                                    setFields1({
                                                        ...fields1,
                                                        subCatArr: [...fields1.subCatArr],
                                                        subCat:""
                                                    })
                                                }
                                                
                                               
                                            }
                                            return;
                                           
                                            
                                        }}
                                        sx={{
                                            "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                                            "& .MuiInputLabel-shrink": { color: "#231f20;" }
                                        }}
                                    name="subCat" onChange={handleChange}  value={fields1.subCat} />
                                    )}
                                />

                            </div>

                            <div className="text-center mb-5 position-relative submit-head">
                                <button type="submit" className="yellow-btn d-flex align-items-center">Update <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                            </div>
                        </form>
                        </div>
                        </div>
                    </Box>
                </Modal>
            </ThemeProvider>
            <ToastContainer rtl/>

         
        </>
    )
}

export default Profile;