import React, { useEffect, useRef, useState } from 'react'
import IMAGES from '../../Middleware/images'
import "../../Assets/Styles/search-result.css"

function TapCardsOrg(props) {
    const [openTapPopup, setOpenTapPopup] = useState(false);
    const [isChecked, setIsChecked] = useState(false)
    let d = new Date();
    let hours = d.getHours().toString().padStart(2, '0');
    let minutes = d.getMinutes().toString().padStart(2, '0');
    let time = `${hours}:${minutes}`;

    const handleOpen = (e, id) => {
        let diffCount = props.item.tap_count - props.item.total_tap
        if (diffCount === props.item.tap_count) {
            e.target.classList.add("colorCheck")
            setOpenTapPopup(true)
        }
        setTimeout(() => {
            e.target.classList.remove("colorCheck")
            setOpenTapPopup(false)
        }, 3000)
        if (props.item?.total_tap !== 0) {
            setIsChecked(!isChecked);
            if (props?.tapId?.includes(id)) {
                let fiterList = props?.tapId?.filter((item) => {
                    return item !== id
                })
                props?.setTapId(fiterList)
            } else if (props?.tapId?.includes(id) === false) {
                props?.setTapId([...props?.tapId, id])
            }
        }
    }
    useEffect(() => {
        props.setApiCallMade(false)
        setIsChecked(false);
    }, [props.apiCallMade]);

    const difference = props?.item?.tap_count - props?.item?.total_tap

    return (
        <>
            <div
                onClick={(e) => handleOpen(e, props?.item.id, props?.totalTap)} style={{ cursor: "pointer" }} className="col-12 col-md-6 col-xl-4 col-xxl-3">
                <div className={difference != props?.item?.tap_count ? "tapped-box-main mb-4 position-relative" : "tapped-box-main after-tap-dark mb-4 position-relative"} key={props?.item.id}>

                    <div className={difference != props?.item?.tap_count ? "tapped-box d-flex justify-content-between align-items-center" : "tapped-box after-tapped d-flex justify-content-between align-items-center"}>

                        <div className="tapped-left-head d-flex">
                            <img src={IMAGES.MASK_GROUP} alt="" className="img-fluid me-2" />
                            <p className="f14-size fw-900 mt-1">Tapped <span className="tapped-head-text">{difference}</span></p>
                        </div>
                        <div className="tapped-right-head d-flex align-items-center">
                            <p className='taps-num'>{difference}/{props?.item?.tap_count} taps this month</p>

                            <input onClick={(e) => handleOpen(e, props?.item.id, props?.totalTap, props?.total_tap, props?.tap_count)} style={{ cursor: "pointer" }} className="form-check-input tap-form-check-input " type="checkbox" value="" checked={isChecked} />
                        </div>
                    </div>
                    <>
                        {
                            openTapPopup && (
                                <div className='position-absolute end-0'>
                                    <div class="alert-head">
                                        <p class="alert-message">This coworker reached their tap limit. Please tap someone else.</p>
                                        <img src={IMAGES.YELLOW_DROP} alt="" class="img-fluid yellow-drop" />
                                    </div>
                                </div>
                            )
                        } </>
                    <div className="tapped-box-head text-center my-4">
                        <h3 className="mb-1">{props?.item.name?.replace(/-/g, ' ')}</h3>
                        <p className={difference != props?.item?.tap_count ? "pink-text" : "black-text"}>{props?.item.designation}</p>
                    </div>
                    <div className="tapped-box-list">
                        <ul className="tap-company-list mb-2">
                            {props?.state?.roleArray.length > 0 && <><li className="tap-company-list-props?.item d-flex align-items-center pb-2">
                                <label className="tap-sq-radio">
                                    <input type="checkbox" checked="checked" name="radio" />
                                    <span className="tap-checkmark"></span>
                                </label>

                                <p className="pe-1 tap-label-head f14-size">Role:</p>
                                <p className="fw-900 tap-one-line-text text-end f14-size">{props?.item.designation}</p>

                            </li>
                            </>}
                            {props?.state?.placesArr.length > 0 && <><li className="tap-company-list-props?.item d-flex align-items-center pb-2">
                                <label className="tap-sq-radio">
                                    <input type="checkbox" checked="checked" name="radio" />
                                    <span className="tap-checkmark"></span>
                                </label>
                                <p className="pe-1 tap-label-head f14-size">Location:</p>
                                <p className="fw-900 tap-one-line-text text-end f14-size">{props?.item.location}</p>

                            </li>
                            </>}

                            {<>
                                <li className="tap-company-list-props?.item d-flex align-items-center pb-2">
                                    <label className="tap-sq-radio">
                                        <input type="checkbox" checked="checked" name="radio" />
                                        <span className="tap-checkmark"></span>
                                    </label>

                                    <p className="pe-1 tap-label-head f14-size">Local time zone:</p>
                                    <p className="fw-900 tap-one-line-text text-end f14-size">{time}</p>

                                </li>
                            </>}
                        </ul>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TapCardsOrg