import React from 'react'
import IMAGES from '../Middleware/images'
import "../Assets/Styles/no-data.css"

function NoData() {
  return (
    <>
     <section className="found-head">
        <div className="container">
            <div className="row justify-content-center align-items-center h-100">
                <div className="col-12">
                    <div className="text-center">
                        <img src={IMAGES.NO_DATA} alt="" className="img-fluid mb-2 mb-lg-4"/>
                        <p className="found-text">404 <br/>Page not found</p>
                    </div>
                </div>
            </div>
        </div>
    </section>
    
    </>
  )
}

export default NoData