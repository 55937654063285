import { Watch } from 'react-loader-spinner'
import "../../src/Assets/Styles/loader.css";
function ReactLoader() {
  return (
    <>
      <div className="loader-new">
        <div className="custom-loading-color" style={{ position: "absolute", top: "350px", left: "700px", color: "#fed71a" }}>
          <Watch
            height="80"
            width="80"
            radius="48"
            color="#fed71a"
            ariaLabel="watch-loading"
            wrapperStyle={{}}
            wrapperClassName=""
            visible={true}
          />
        </div>
      </div>
    </>
  )
}
export default ReactLoader;