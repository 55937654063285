import React, { useState, useEffect } from 'react'
import IMAGES from '../../Middleware/images'
import "../../Assets/Styles/search-result.css"
import { useNavigate, useLocation } from 'react-router-dom'
import Box from '@mui/material/Box';
import { Helmet } from 'react-helmet';
import titles from '../../Helper/title';
import Modal from '@mui/material/Modal';
import { TextField } from '@mui/material';
import ERR_MESSAGE from '../../Helper/error-helper';
import apiHelper from '../../Helper/api-helper';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ReactLoader from '../../ControlComponents/react-loader';
import TapCardsUser from './tap-cards-user';
const style = {
  // position: 'absolute',
  // top: '50%',
  // left: '50%',
  // transform: 'translate(-50%, -50%)',
  // width: 676,
  // bgcolor: 'background.paper',
  // borderRadius: "20px",
  // boxShadow: 24,
  // border: "1px solid #6ED7EF",
  // p: 0,
  // outline: 0,
  // textAlign: "center",
};

let updateIsChecked = "";

function SearchResult() {
  const [cc_email, setCc_email] = useState(true);
  const [type, setType] = useState();

  const [apiCallMade, setApiCallMade] = useState(false);
  const [orgList, setOrgList] = useState([])
  const [userDetail, setUserDetail] = useState(null)
  const { state } = useLocation()
  const [open, setOpen] = useState(false);
  const [tapId, setTapId] = useState([])
  const [openDone, setOpenDone] = useState(false);
  const [totalTap, setTotalTap] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [errors, setErrors] = useState("");


  const [fields, setFields] = useState({
    message: "",
  });

  const handleTapModal = (setIsChecked) => {
    setOpen(true)
    updateIsChecked = (newIsChecked) => {
      setIsChecked(newIsChecked);
    };
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (value.length <= 255) {
      setFields({
        ...fields,
        [name]: value,
      });
    }
  };
  const resetFields = () => {

    setFields({
      message: "",
      goal: "",
      description: "",
      timing: "",

    })
  }

  const navigate = useNavigate();


  const handleClose = () => {
    setFields({
      ...fields,
      message: ""
    })
    setOpen(false);
  }
  const handleDoneOpen = () => {
    setOpen(false)
    setOpenDone(true);
  }
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    const { message, goal, description, timing } = fields;
    if ((!message || message.trim().length === 0) && type == "quick") {
      formIsValid = false;
      errors["message"] = ERR_MESSAGE.MESSAGE_EMPTY
    }

    if ((!goal || goal.trim().length === 0) && type == "detail") {
      formIsValid = false;
      errors["goal"] = ERR_MESSAGE.MESSAGE_EMPTY
    }

    if ((!description || description.trim().length === 0) && type == "detail") {
      formIsValid = false;
      errors["description"] = ERR_MESSAGE.MESSAGE_EMPTY
    }

    if ((!timing || timing.trim().length === 0) && type == "detail") {
      formIsValid = false;
      errors["timing"] = ERR_MESSAGE.MESSAGE_EMPTY
    }

    setErrors(errors);
    return formIsValid;
  };

  async function postAPI(e) {
    e.preventDefault();
    if (handleValidation()) {
      let data = {
        "tap_user_ids": tapId,
        "message": type == "quick" ? fields.message : fields.goal,
        "type": type,
        "cc_email": cc_email,
        // "goal": fields.goal,
        "description": fields.description,
        "timing": fields.timing,
      };

      setLoading(true);
      let result = await apiHelper.postRequest("organization/tap-user", data)
      if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
        setLoading(false)
        handleDoneOpen()
        setTapId(null)
        setFields({
          ...fields,
          message: ""
        })
        setApiCallMade(true)
        if (totalTap > 0) {
          setTotalTap(totalTap - 1)
        }
        postAPIResult()
        setTapId([])
      }
      else {
        setLoading(false)
      }
    }
  }
  useEffect(() => {
    if (state?.roleArray?.length > 0 || state?.placeArr?.length > 0 || state?.selectedIds?.length > 0 || state?.longitude?.length > 0 || state?.latitude?.length > 0) {
      postAPIResult()
    }
    else {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.includes('category_') && key.endsWith('_values')) {
          localStorage.removeItem(key);
        }
      }
    }
    getMyDetailAPI()
  }, [])

  async function getMyDetailAPI() {
    setLoading(true)
    let result = await apiHelper.getRequest("auth/get-my-detail")
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setTotalTap(result?.body?.total_tap)
      setUserDetail(result?.body)
      setLoading(false)
    }
    else {
      setLoading(false)
    }
  }
  async function postAPIResult() {
    let data = {};
    if (state?.roleArray?.length > 0)
      data.roles = state?.roleArray?.toString();

    if (state?.placeArr?.length > 0)
      data.location = state?.placeArr?.toString();

    if (state?.selectedIds?.length > 0)
      data.sub_cat_ids = state?.selectedIds

    if (state?.latitude?.length > 0)
      data.latitude = state?.latitude?.toString();
    if (state?.longitude?.length > 0)
      data.longitude = state?.longitude?.toString();
    setLoading(true);
    let result = await apiHelper.postRequest("organization/global-search-member", data)
    if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
      setLoading(false);
      setOrgList(result?.body)
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key.includes('category_') && key.endsWith('_values')) {
          localStorage.removeItem(key);
        }
      }

    }
    else {
      setLoading(false)

    }

  }

  const handleDoneClose = () => {
    setOpenDone(false);
  }

  return (
    <>
      <Helmet>
        <title>
          {titles.searchResults}
        </title>
      </Helmet>
      <div className='loader'>
        {isLoading && <ReactLoader />}
      </div>
      <section className="serach-result-section">
        <div className="container">
          <div className="row align-items-center pt-4 pb-4">

            <div className="col-12 col-lg-4">
              <nav>
                <ul className="d-flex gap-2 align-items-center mb-0">
                  <li><img src={IMAGES.BACK_ARROW} style={{ cursor: "pointer" }} onClick={() => navigate(-1)} /></li>
                  <li>Go back and try another search</li>
                </ul>
              </nav>
            </div>
            <div className="col-12 col-lg-8">
              <div className="row align-items-center text-end text-md-start">
                <div className="col-12">
                  <div className="switch-toggle-head text-end">
                    <div className='d-flex justify-content-end align-items-center switch-delete-acc'>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-12">
              <div className="serach-result-head">
                <h3>your search</h3>
                <div className="category-exp-filters my-2">
                  {
                    state?.searchArray?.map((item) => {
                      return (
                        <p className="result-category-filter-chips">{item}<img src={IMAGES.CROSS} className="result-chip-img" alt="img-fluid" /></p>
                      )
                    })
                  }

                </div>
              </div>
            </div>
            {orgList?.length !== 0 ? <><div className="col-12 col-lg-6 my-4">
              <div className="search-result-seb-head">
                <h2>Search Results</h2>
                <p>Please select the members from the below results that you would like to tap</p>

              </div>
            </div>
              <div className="col-12 col-lg-6 my-1 my-lg-4 text-end">
                <button type="button" disabled={tapId?.length === 0 ? true : false} style={{ backgroundColor: tapId?.length === 0 ? "gray" : "  " }} onClick={() => { setOpen(true) }} className="proceed-yellow-btn position-relative d-flex align-items-center ms-auto" ><span>Tap Members</span> <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
              </div></>
              :
              <div><p className='my-3'>Sorry, there were no results for your search. Try searching with different keywords</p></div>
            }
            {
              orgList?.map((item) => {
                return (
                  item?.invite_status === 1 &&
                  <TapCardsUser state={state} tapId={tapId} handleTapModal={handleTapModal} setTapId={setTapId} setOpen={setOpen} setFields={setFields} fields={fields} item={item} totalTap={totalTap} setApiCallMade={setApiCallMade} apiCallMade={apiCallMade} />
                )
              })
            }

          </div>
        </div>
      </section>

      {/* Modals starts here */}

      {/* Message Mdal */}
      <Modal
        open={open}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div onClick={handleClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-1 ">
                <h1>Tap the selected members</h1>
              </div>
              {/* <div className="position-relative text-center">
                <p>
                  A brief note would be helpful so they know why you’re tapping them.
                </p>
              </div> */}
              <form onSubmit={postAPI}>
                <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5 card p-4 bg-gray rounded-4">
                  <label className="sq-radio" >
                    <input type="checkbox" name="radio" checked={type == "quick" ? true : false} onClick={() => { setType('quick'); resetFields() }} />
                    <div className="ignin-head text-start ">
                      <h3>Quick Tap </h3>
                      <h5>Brief message explaining why you’re tapping them.</h5>
                    </div>
                    <span className="checkmark mt-2 mr-4"></span>
                  </label>
                  <label className="sq-radio">
                    <input type="checkbox" name="radio" checked={type == "detail" ? true : false} onClick={() => { setType('detail'); resetFields() }} />
                    <div className="ignin-head text-start ">
                      <h3>Mini brief</h3>
                      <h5>Include details like the goal of your ask, why you think they might be able to help or provide you, and timing.</h5>
                    </div>
                    <span className="checkmark mt-2"></span>
                  </label>

                </div>
                {type == "quick" && (
                  <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                    <div className="signin-head text-start pb-2">
                      <h3 className='lable-color'>quick tap : </h3>
                    </div>
                    <TextField rows={5} multiline= {true} fullWidth placeholder="Type a brief message here to let them know why you’re tapping them." variant="outlined"
                      sx={{
                        '& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '20px' },
                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                      }}
                      name="message" onChange={handleChange} value={fields.message} />
                    {errors.message && <span className="err_msg">{errors.message}</span>}
                  </div>
                )}

                {type == "detail" && (<>
                  <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                    <div className="signin-head text-start pb-2">
                      <h3 className='lable-color'>Tap goal: </h3>
                    </div>
                    <TextField rows={5} multiline= {true} fullWidth placeholder="Type a brief message here describing what you’re trying to achieve by tapping them (e.g., I want to learn more about X topic)" variant="outlined"
                      sx={{
                        '& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '20px' },
                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                      }}
                      name="goal" onChange={handleChange} value={fields.goal} />
                    {errors.goal && <span className="err_msg">{errors.goal}</span>}
                  </div>
                  <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                    <div className="signin-head text-start pb-2">
                      <h3 className='lable-color'>I thought you might be able to provide: </h3>
                    </div>
                    <TextField rows={5} multiline= {true} fullWidth placeholder="Type a brief message here describing what kind of help you’re looking for (e.g., a 15 minute call, a document, attendance, consultation, network connection, etc.)" variant="outlined"
                      sx={{
                        '& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '20px' },
                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                      }}
                      name="description" onChange={handleChange} value={fields.description} />
                    {errors.description && <span className="err_msg">{errors.description}</span>}
                  </div>
                  <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                    <div className="signin-head text-start pb-2">
                      <h3 className='lable-color'>Timing: </h3>
                    </div>
                    <TextField rows={5} multiline= {true} fullWidth placeholder="Type a brief message here outlining the project schedule and/or the deadline of your request." variant="outlined"
                      sx={{
                        '& .css-8ewcdo-MuiInputBase-root-MuiOutlinedInput-root': { paddingLeft: '20px' },
                        "& fieldset": { border: '2px solid #231f20', borderRadius: '30px' },
                        "& .MuiInputLabel-shrink": { color: "#231f20;" }
                      }}
                      name="timing" onChange={handleChange} value={fields.timing} />
                    {errors.timing && <span className="err_msg">{errors.timing}</span>}
                  </div>
                </>
                )}

                {type &&
                  <div className="mb-5 text-center position-relative submit-head">
                    <div className='row mb-5' align="center">
                      <div className='col-md-6 text-center' align="center">
                        <div className="align-items-center text-center" align="center" style={{ minWidth: '245px' }}>
                          <div className="ignin-head text-center">
                            <h5>cc myself on this tap email</h5>
                          </div>
                          <div className="toggle-switch ">

                            <input type="checkbox" id="toggle" className="toggle-checkbox" checked={cc_email} onClick={() => setCc_email(!cc_email)} />
                            <label className="toggle-label" htmlFor="toggle">
                              <span className="toggle-inner"></span>
                              <span className="toggle-switch">{cc_email ? 'ON' : 'OFF'}</span>
                            </label>

                          </div>
                        </div>
                      </div>
                      <div className='col-md-6 text-center submit-head'>
                        <button type="submit" className="yellow-btn d-flex align-items-center mt-3">Tap<img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                      </div>
                    </div>
                  </div>
                }
              </form>
            </div>
          </div>
        </Box>
      </Modal>

      {/* Tapped Modal */}
      <Modal
        open={openDone}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div onClick={handleDoneClose} className=' position-relative mb-0 text-end pt-3 pe-3' style={{ cursor: "pointer" }}>

                <img src={IMAGES.CROSS} />
              </div>
              <form onSubmit={handleDoneClose}>
                <div className="signin-head text-center pb-3">
                  <h2>You just tapped the selected members</h2>
                </div>
                <div className="mb-4  position-relative text-center">
                  <p>
                    We sent an email with your note to your coworker. Your collaboration is one step closer.</p>
                </div>
                <div className="mb-5 text-center position-relative submit-head" >
                  <button type="submit" className="yellow-btn d-flex align-items-center">All Done! <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                </div>
              </form>
            </div>
          </div>
        </Box>

      </Modal>
    </>
  )
}

export default SearchResult