import React from 'react'
import IMAGES from '../Middleware/images'

const HeaderSignUp = () => {
  return (
    <>
     <nav className="navbar navbar-expand-lg yellow-back navbar-header">
            <div className="container">
                <a className="navbar-brand p-0"><img src={IMAGES.HAPTAPT_BLACK} className="img-fluid" alt="haptapt-logo" width={178} height={56}/></a>
            <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarNavDropdown">
                <ul className="navbar-nav ms-auto navbar-item-head">
               
                </ul>
            </div>
            </div>
    </nav>
    </>
  )
}

export default HeaderSignUp