import React, { useEffect, useState } from 'react';
import TextField from "@mui/material/TextField";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import apiHelper from "../Helper/api-helper";
import ERR_MESSAGE from "../Helper/error-helper";
import DEVELOPMENT_CONFIG from "../Helper/config";
import ReactLoader from "../ControlComponents/react-loader";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "react-datepicker/dist/react-datepicker.css";
import IMAGES from "../Middleware/images";
import DatePickerValue from "./date-picker";

const style = {
  // position: "absolute",
  // top: "50%",
  // left: "50%",
  // transform: "translate(-50%, -50%)",
  // width: 676,
  // bgcolor: "background.paper",
  // borderRadius: "20px",
  // boxShadow: 24,
  // border: "1px solid #6ED7EF",
  // p: 0,
  // outline: 0,
  // textAlign: "center",
};
const ManageSubscription = ({ subModal, setSubModal, orgId, price, totalEmployee, start_Date, end_Date, getAPI }) => {
  const [isLoading, setLoading] = useState(false);
  const [startDate, setStartDate] = useState(start_Date);
  const [endDate, setEndDate] = useState(end_Date);
  const [startDate1, setStartDate1] = useState("YYYY-MM-DD");
  const [endDate1, setEndDate1] = useState("YYYY-MM-DD");
  const [errors, setErrors] = useState();
  const [fields, setFields] = useState({
    price: "",
    users: "",
  });
  const modalClose = () => {
    // document.body.classList.remove("modal-open");


    setErrors("");
    
    // setFields({
    //   users: "",
    //   price: "",
    // })
    // setStartDate("");
    // setStartDate1("YYYY-MM-DD");
    // setEndDate("");
    // setEndDate1("YYYY-MM-DD");
    setSubModal(false);
  };

  const handleChange = (e) => {
    setErrors("");
    const name = e.target.name;
    let value = e.target.value;
    if (name === "price" || name === "users") {
      value = e.target.value.replace(/[^0-9]/g, "");
    }
    setFields({
      ...fields,
      [name]: value,
    });
  };
  const handleValidation = () => {
    let errors = {};
    let formIsValid = true;
    const { users, price } = fields;
    if (!users || users.trim().length === 0) {
      formIsValid = false;
      errors["users"] = ERR_MESSAGE.USERS_EMPTY;
    }

    if (!price || price.trim().length === 0) {
      formIsValid = false;
      errors["price"] = ERR_MESSAGE.PRICE_EMPTY;
    }
    if (!startDate || startDate === "") {
      formIsValid = false;
      errors["startDate"] = ERR_MESSAGE.START_DATE_EMPTY;

    }

    else {

      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(startDate);
      selectedDate.setHours(0, 0, 0, 0);
    //   if (selectedDate < currentDate) {
    //     formIsValid = false;
    //     errors["startDate"] = ERR_MESSAGE.START_DATE_INVALID;
    //   }
    }
    if (!endDate || endDate === "") {
      formIsValid = false;
      errors["endDate"] = ERR_MESSAGE.END_DATE_EMPTY;
    }
    else {
      const currentDate = new Date();
      currentDate.setHours(0, 0, 0, 0);
      const selectedDate = new Date(endDate);
      selectedDate.setHours(0, 0, 0, 0);
      if (selectedDate < currentDate) {
        formIsValid = false;
        errors["endDate"] = ERR_MESSAGE.START_DATE_INVALID;
      }

    }
    setErrors(errors);
    return formIsValid;

  };
  const organizationSuccess = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };
  const organizationError = (msg) => {
    toast.success(msg, {
      autoClose: 5000,
    });
  };

  async function postAPI(e) {
    e.preventDefault();

    if (handleValidation()) {
      let data = JSON.stringify({
        "orgid": orgId,
        "start_date": startDate,
        "end_date": endDate,
        "total_user_allow": fields.users,
        "price": fields.price,
      });

      setLoading(true);

      try {
        let result = await apiHelper.postRequest("auth/manual-transation", data);

        if (result?.code === DEVELOPMENT_CONFIG.statusCode) {
          setLoading(false);
          organizationSuccess(result.message);
          modalClose();
          setFields({
            ...fields,
            users: "",
            price: "",
          })
          setStartDate1("YYYY-MM-DD");
          setEndDate("");
          setEndDate1("YYYY-MM-DD");
          setStartDate("");
          getAPI();
        } else {
          organizationError(result?.message);
          setLoading(false);
        }
      } catch (error) {
        organizationError("An error occurred during form submission.");
        setLoading(false);
      }
    }
  }

  useEffect(() => {
    // setFields(()=> { return ({
    //   users:Number(props?.totalEmployee),
    //   price: Number(props?.price),
    // })})

    setFields({
      ...fields,
      users: totalEmployee?.toString(),
      price: price?.toString(),
    })

    setStartDate(start_Date)
    setEndDate(end_Date)

  }, [orgId]);

  return (
    <>
      <div className="loader">{isLoading && <ReactLoader />}</div>
      <Modal
        open={subModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} className="modal modal-custom">
          <div className="modal-dialog modal-dialog-centered modal-dialog-width">
            <div className="modal-content modal-content-custom">
              <div
                onClick={modalClose}
                className=" position-relative mb-0 text-end pt-3 pe-3"
                style={{ cursor: "pointer" }}
              >
                <img src={IMAGES.CROSS} />
              </div>
              <div className="signin-head text-center pb-3">
                <h1>Manage Subscription</h1>
              </div>
              <div className="position-relative">

              </div>
              <form onSubmit={(e) => postAPI(e)}>
                <div className="d-flex align-items-center justify-content-between">
                  <div className=" position-relative mx-1 mx-sm-5 custom-date-input">
                    <DatePickerValue
                      setErrors={setErrors}
                      value={startDate}
                      setFn={setStartDate}
                      value1={startDate1}
                      setFn1={setStartDate1}
                      label={"Start Date"}
                      className=""
                    />
                    {errors?.startDate && (
                      <span className="err_msg">{errors?.startDate}</span>
                    )}
                  </div>
                  <div className=" position-relative mx-1 mx-sm-5 custom-date-input">
                    <DatePickerValue
                      setErrors={setErrors}
                      value={endDate}
                      setFn={setEndDate}
                      value1={endDate1}
                      setFn1={setEndDate1}
                      label={"End Date"}
                      className=""
                    />
                    {errors?.endDate && (
                      <span className="err_msg">{errors.endDate}</span>
                    )}
                  </div>
                </div>
                <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                  <TextField
                    fullWidth
                    label="Enter Total Number Of Employees"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": {
                        color: "#231f20;",
                      },
                    }}
                    name="users"
                    value={fields.users}
                    onChange={handleChange}
                    InputProps={{
                      inputProps: {
                        inputMode: "numeric",
                        maxLength: 10,
                      },
                    }}
                  />

                  {errors?.users && <span className="err_msg">{errors.users}</span>}
                </div>

                <div className="my-4 my-lg-5 position-relative mx-1 mx-sm-5">
                  <TextField
                    fullWidth
                    label="Enter price"
                    variant="outlined"
                    sx={{
                      "& fieldset": {
                        border: "2px solid #231f20",
                        borderRadius: "30px",
                      },
                      "& .MuiInputLabel-shrink": { color: "#231f20;" },
                    }}
                    name="price"
                    value={fields.price}
                    onChange={handleChange}
                    InputProps={{

                      inputProps: {
                        inputMode: "numeric",
                        maxLength: 12,
                      },
                    }}
                  />
                  {errors?.price && (
                    <span className="err_msg">{errors?.price}</span>
                  )}
                </div>
                <div className="mb-5 text-center position-relative submit-head">
                  <button
                    type="submit"
                    className="yellow-btn d-flex align-items-center "
                  >
                    Submit{" "}
                    <img
                      src={IMAGES.ARROW_GREY}
                      alt=""
                      className="img-fluid ms-2"
                    />
                  </button>
                </div>
              </form>
            </div>
          </div>
        </Box>
      </Modal>
      <ToastContainer rtl />
    </>
  );
};

export default ManageSubscription;
