import React, { useEffect } from 'react'
import IMAGES from '../Middleware/images'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import video from "../Assets/Images/tutorialVideo.mp4"
import introHead from "../Assets/Images/Intro_logo.png"
import "../Assets/Styles/modal.css"


const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 876,
    // bgcolor: 'background.paper',
    // borderRadius: "16px",
    // boxShadow: 24,
    // border: "1px solid #6ED7EF",
    // p: 0,
    // outline: 0,
    // textAlign: "center",
};

const TutorialModal = (props) => {
    const { open, setOpen } = props;
    useEffect(() => {
        if (open) {
            document.body.classList.add("modal-open");
        } else {
            document.body.classList.remove("modal-open");
        }
    }, [open]);
    
    const handleClose = () => {
        props?.setOpen(false);
    }
    const signUpNow = (e) => {
        document.body.classList.remove("modal-open");
        e.preventDefault();
        props?.setOpen(false);
    }
    return (
        <>
            <Modal
                open={props?.open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style} className="modal modal-custom">
                    <div className="modal-dialog modal-dialog-centered modal-dialog-width">
                        <div className="modal-content modal-content-custom">
                            <div className="signin-head text-center px-3 px-sm-0 pt-3 pt-sm-4 pb-2 d-flex align-items-center justify-content-center">

                                <img src={introHead} className='img-fluid tutorial-video' />
                            </div>
                            <div className="position-relative px-3 px-lg-5 py-2">
                                <p className="px-0 px-sm-3 px-lg-4 pb-3">
                                    Speed up collaboration by easily finding coworkers who have knowledge, expertise, or interests that will help you do your job, make useful connections, and make work life better.
                                </p>
                            </div>
                            <form onSubmit={signUpNow}>
                                <div className="position-relative px-3 px-lg-5 text-center">
                                    <video poster ={IMAGES.HAPTAPT_THUMBNAIL} className="modal-video" src={video} controls></video>
                                </div>
                                <div className="mb-4 mb-xxl-5 mt-3 text-center position-relative submit-head">
                                    <button type="submit" className="yellow-btn d-flex align-items-center">Signup Now <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" /></button>
                                </div>
                            </form>
                        </div>
                    </div>
                </Box>
            </Modal>
        </>
    )
}

export default TutorialModal
