import React, { useEffect, useState, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IMAGES from '../../Middleware/images';
import ClearIcon from '@mui/icons-material/Clear';
import "../../Assets/Styles/profile.css"
import ERR_MESSAGE from '../../Helper/error-helper';
import apiHelper from '../../Helper/api-helper';
import ReactLoader from '../../ControlComponents/react-loader';
import DEVELOPMENT_CONFIG from "../../Helper/config"
import ROUTES from '../../Middleware/routes';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { createTheme } from '@mui/material/styles';
import { useLocation, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import titles from '../../Helper/title';
import { StandaloneSearchBox, LoadScript } from "@react-google-maps/api";
import { cleanDigitSectionValue } from '@mui/x-date-pickers/internals/hooks/useField/useField.utils';
import { InviteSent } from './component/invite-sent';
const libraries = ['places'];

const style = {
    // position: 'absolute',
    // top: '50%',
    // left: '50%',
    // transform: 'translate(-50%, -50%)',
    // width: 676,
    // bgcolor: 'background.paper',
    // borderRadius: "20px",
    // boxShadow: 24,
    // border: "1px solid #6ED7EF",
    // p: 0,
    // outline: 0,
    // textAlign: "center",
};

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const ProfileConfirmation = () => {

    const [isLoading, setLoading] = useState(false);
    const navigate = useNavigate();
    return (
        <>
            <Helmet>
                <title>Profile conformation</title>
            </Helmet>
            <div className='loader'>
                {isLoading && <ReactLoader />}
            </div>
            <section className="pt-5">
                <div className="container">
                    <div className="row align-items-center pb-4">
                        {/* Text, Image, and Buttons column */}
                        <div className="col-md-6 d-flex justify-content-center align-items-center pt-5">
                            <div style={{ width: '70%' }}>
                                <div className="signin-head text-center pb-1">
                                    <h1>you’re tapped in!</h1>
                                </div>
                                <div className="position-relative text-center mt-4">
                                    <p className='pb-4'>
                                        You’re now instantly discoverable when your coworkers search using tapt tags associated with you. You can now also search for coworkers.
                                    </p>
                                    <p className='pb-4'> haptapt is here to help make life at work better by equipping teams with a quick and easy way to connect and learn from each other.</p>
                                    <p className='pb-4'> Happy collaborating!</p>
                                </div>

                                {/* Image, only shows on mobile devices */}
                                <div className="d-block d-md-none text-center mb-4">
                                    <img src={IMAGES.PROFILE_CONFIRMATION} className="img-fluid" alt="Profile Confirmation" />
                                </div>

                                <div className="mb-5 position-relative submit-head mt-4 d-flex flex-column align-items-center">
                                    <button 
                                        type="submit" 
                                        className="btn yellow-btn d-flex align-items-center mb-4" 
                                        style={{ width: '230px', paddingLeft: '64px' }} 
                                        onClick={() => navigate(ROUTES.SEARCH_DATABASE)}
                                    >
                                        Search
                                        <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                                    </button>
                                    <button 
                                        type="submit" 
                                        className="yellow-btn d-flex align-items-center mt-3" 
                                        style={{ background: '#6ED7EF', minWidth: '230px', padding: '10px 42px' }} 
                                        onClick={() => navigate(ROUTES.USER_PROFILE)}
                                    >
                                        Go To Profile
                                        <img src={IMAGES.ARROW_GREY} alt="" className="img-fluid ms-2" />
                                    </button>
                                </div>
                            </div>
                        </div>

                        {/* Image column for larger screens */}
                        <div className="col-md-6 d-none d-md-flex justify-content-center align-items-center">
                            <img src={IMAGES.PROFILE_CONFIRMATION} className="img-fluid" alt="Profile Confirmation" />
                        </div>
                    </div>
                </div>
            </section>

            <ToastContainer rtl />
        </>


    )
}
export default ProfileConfirmation;